const CorrectMeBlock: React.FC<{
  onClick?: () => void;
  text?: string;
}> = ({ onClick, text = "it's wrong?" }) => (
  <p className="flex items-center mt-6">
    <span
      className="text-[22px] font-medium"
      style={{
        color: '#FFFFFF',
      }}
    >
      {text}
    </span>
    <span
      className="ml-2 text-[22px] font-medium cursor-pointer border-b-2 border-[#00C7A8] hover:border-opacity-70"
      style={{
        color: '#00C7A8',
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      Correct me
    </span>
  </p>
);

export default CorrectMeBlock;
