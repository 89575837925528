interface SearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
}

const Search: React.FC<SearchProps> = ({ searchQuery, onSearchChange }) => {
  return (
    <div className="flex items-center border-y-2 border-t-[#1c1c22] border-b-[#403f4d] rounded-[8px] bg-[#26252E]">
      <div className="p-2">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 21L16.65 16.65M11 6C13.7614 6 16 8.23858 16 11M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
            stroke="#403F4D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <input
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Search"
        className="flex h-10 pr-2 w-full bg-[#26252E] rounded-r-[8px] placeholder:text-[#5f5d74] border-none placeholder:text-base ring-0 focus:ring-0 focus:outline-none text-[#938EA4] text-base"
      />
    </div>
  );
};

export default Search;
