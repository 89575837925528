import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  writeBatch,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import { db, auth } from '@/core/setup_firebase';
import { useAuth } from '../auth/AuthContext';
import { UserProfile } from '@/types/UserProfile';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';
import { ProjectLayout } from '../kadencebio/components/ProjectsLayout';
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import plusIcon from '@/images/plus-lg.svg';

type SortIconProps = {
  column: keyof UserProfile;
  currentColumn: keyof UserProfile;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

interface NewUserForm {
  email: string;
  displayName: string;
  password: string;
  role: UserProfile['role'];
}

const UserManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [changedUsers, setChangedUsers] = useState<{
    [key: string]: UserProfile['role'];
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const [sortColumn, setSortColumn] = useState<keyof UserProfile>('email');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState<NewUserForm>({
    email: '',
    displayName: '',
    password: '',
    role: 'viewer',
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentUser?.role === 'super-admin') {
        const usersCollection = collection(db, 'users');
        const userSnapshot = await getDocs(usersCollection);
        const userList = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          role: doc.data().role || 'viewer',
        })) as UserProfile[];
        setUsers(userList);
      }
      setIsLoading(false);
    };

    fetchUsers();
  }, [currentUser]);

  const handleRoleChange = (userId: string, newRole: UserProfile['role']) => {
    setChangedUsers((prev) => ({ ...prev, [userId]: newRole }));
  };

  const saveChanges = async () => {
    const batch = writeBatch(db);

    Object.entries(changedUsers).forEach(([userId, newRole]) => {
      const userRef = doc(db, 'users', userId);
      batch.update(userRef, { role: newRole });
    });

    await batch.commit();

    setUsers(
      users.map((user) =>
        changedUsers[user.id] ? { ...user, role: changedUsers[user.id] } : user,
      ),
    );

    setChangedUsers({});
  };

  const handleSort = (column: keyof UserProfile) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    return 0;
  });

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Check if email already exists
      const signInMethods = await fetchSignInMethodsForEmail(
        auth,
        newUser.email,
      );
      if (signInMethods.length > 0) {
        alert(
          'This email is already registered. Please use a different email.',
        );
        return;
      }

      // Store current user
      const currentAuthUser = auth.currentUser;
      if (!currentAuthUser) {
        throw new Error('No authenticated user found');
      }

      // Create auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password,
      );

      // IMPORTANT: Sign back in as the original user BEFORE creating the document
      await auth.updateCurrentUser(currentAuthUser);

      // Now create user document as super-admin
      const userRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userRef, {
        email: newUser.email,
        displayName: newUser.displayName,
        role: newUser.role,
      });

      // Update local state
      setUsers([
        ...users,
        {
          id: userCredential.user.uid,
          email: newUser.email || '',
          displayName: newUser.displayName || '',
          role: newUser.role || 'viewer',
          photoURL: '',
        },
      ]);

      setIsModalOpen(false);
      setNewUser({
        email: '',
        displayName: '',
        password: '',
        role: 'viewer',
      });
    } catch (error: any) {
      console.error('Error creating user:', error);

      // Handle specific Firebase auth errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          alert('This email address is already being used by another account.');
          break;
        case 'auth/invalid-email':
          alert('The email address is not valid.');
          break;
        case 'auth/operation-not-allowed':
          alert(
            'Email/password accounts are not enabled. Please contact support.',
          );
          break;
        case 'auth/weak-password':
          alert('The password is too weak. Please choose a stronger password.');
          break;
        default:
          alert(`Failed to create user: ${error.message}`);
      }
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      setUsers(users.filter((user) => user.id !== userId));
      setShowDeleteConfirm(null);
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Error deleting user');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (currentUser?.role !== 'super-admin') {
    return <div>You don't have access to this page.</div>;
  }

  return (
    <ProjectLayout title="User Management">
      <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
        <div className="flex flex-row p-6">
          <main className="w-9/12 flex-shrink-0 pl-8 pt-2 flex flex-col">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-4xl font-semibold tracking-tight">
                User Management
              </h1>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="btn btn-primary btn-sm text-neutral-800 font-normal"
                >
                  Create New User{' '}
                  <img src={plusIcon} className="inline-flex w-4 h-4" alt="" />
                </button>
                <div className="h-8 border-r border-zinc-700"></div>
              </div>
            </div>

            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-[#2D2D32] p-6 rounded-lg w-96">
                  <h2 className="text-xl font-semibold mb-4">
                    Create New User
                  </h2>
                  <form onSubmit={handleCreateUser}>
                    <div className="space-y-4">
                      <input
                        type="email"
                        placeholder="Email"
                        value={newUser.email}
                        onChange={(e) =>
                          setNewUser({ ...newUser, email: e.target.value })
                        }
                        className="w-full bg-gray-800 p-2 rounded"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Display Name"
                        value={newUser.displayName}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            displayName: e.target.value,
                          })
                        }
                        className="w-full bg-gray-800 p-2 rounded"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Password"
                        value={newUser.password}
                        onChange={(e) =>
                          setNewUser({ ...newUser, password: e.target.value })
                        }
                        className="w-full bg-gray-800 p-2 rounded"
                        required
                      />
                      <select
                        value={newUser.role}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            role: e.target.value as UserProfile['role'],
                          })
                        }
                        className="w-full bg-gray-800 p-2 rounded"
                      >
                        <option value="viewer">Viewer</option>
                        <option value="moderator">Moderator</option>
                        <option value="super-admin">Super Admin</option>
                      </select>
                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                      <button
                        type="button"
                        onClick={() => setIsModalOpen(false)}
                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            <div className="overflow-auto">
              {users.length > 0 ? (
                <table className="min-w-full bg-[#1F1F23] text-gray-300">
                  <thead>
                    <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                      <th
                        className="p-2 cursor-pointer font-normal"
                        onClick={() => handleSort('email')}
                      >
                        Email
                        <SortIcon
                          column="email"
                          currentColumn={sortColumn}
                          direction={sortDirection}
                        />
                      </th>
                      <th
                        className="p-2 cursor-pointer font-normal"
                        onClick={() => handleSort('role')}
                      >
                        Role
                        <SortIcon
                          column="role"
                          currentColumn={sortColumn}
                          direction={sortDirection}
                        />
                      </th>
                      <th className="p-2 font-normal">Status</th>
                      <th className="p-2 font-normal">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedUsers.map((user) => (
                      <tr
                        key={user.id}
                        className="border-b border-gray-700 text-start text-sm"
                      >
                        <td className="p-2">{user.email}</td>
                        <td className="p-2">
                          <select
                            value={changedUsers[user.id] || user.role}
                            onChange={(e) =>
                              handleRoleChange(
                                user.id,
                                e.target.value as UserProfile['role'],
                              )
                            }
                            className="bg-gray-800 text-gray-200 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="viewer">Viewer</option>
                            <option value="moderator">Moderator</option>
                            <option value="super-admin">Super Admin</option>
                          </select>
                        </td>
                        <td className="p-2">
                          {changedUsers[user.id] &&
                            changedUsers[user.id] !== user.role && (
                              <span className="text-yellow-400 text-sm">
                                Unsaved
                              </span>
                            )}
                        </td>
                        <td className="p-2">
                          {showDeleteConfirm === user.id ? (
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-gray-400">
                                Confirm delete?
                              </span>
                              <button
                                onClick={() => handleDeleteUser(user.id)}
                                className="text-red-500 hover:text-red-600"
                              >
                                Yes
                              </button>
                              <button
                                onClick={() => setShowDeleteConfirm(null)}
                                className="text-gray-400 hover:text-gray-300"
                              >
                                No
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => setShowDeleteConfirm(user.id)}
                              className="text-red-500 hover:text-red-600"
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="flex justify-center items-center h-64">
                  <p className="text-2xl text-gray-400">No users found.</p>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </ProjectLayout>
  );
};

export default UserManagementPage;
