import { SiteMapSite } from '@/types/SiteMap';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import CompletedStudyCard from './CompletedStudyCard';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Sponsor, StudyDetails } from '@/types/Library';

// Fix for default marker icon in react-leaflet
const defaultIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const SiteCard: React.FC<{
  studies_in_condition?: Sponsor[];
  site: SiteMapSite;
  onToggleSelect: (site: SiteMapSite) => void;
}> = ({ site, onToggleSelect }) => {
  const handleMapClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent div's onClick
    const [lat, lng] = site.coordinates;
    window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
  };

  // search sponsors to find this site with related studies
  const studies: StudyDetails[] = [];
  return (
    <div
      className="bg-[#2A2A2E] rounded-lg cursor-pointer hover:bg-[#353539] transition-colors"
      onClick={() => onToggleSelect(site)}
    >
      <div className="flex justify-between items-stretch">
        <div className="w-2/3 p-6 border-r border-[#4B4A55]">
          <h3 className="text-white text-4xl mb-4 break-words">
            {site.site_name}
          </h3>
          <p className="text-[#9491a7] text-sm">
            <span className="text-white">
              {site.num_completed_studies} Completed Studies.{' '}
              {site.num_active_studies} Active Studies.
            </span>
          </p>
          {site.studies_in_condition.map((study, index) => (
            <CompletedStudyCard study={study} key={index} />
          ))}
        </div>

        <div className="w-1/3">
          <div
            className="h-full rounded-lg overflow-hidden relative cursor-pointer group"
            onClick={handleMapClick}
          >
            <div className="absolute inset-0 z-20 bg-black/0 group-hover:bg-black/50 transition-all duration-200" />
            <div className="absolute top-1/2 left-0 right-0 -translate-y-1/2 z-30 text-center text-white font-normal opacity-0 group-hover:opacity-100 transition-all duration-200">
              Open in GMaps{' '}
              <svg
                className="inline-block ml-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: '1em',
                  width: '1em',
                  verticalAlign: 'middle',
                  marginBottom: '2px',
                }}
              >
                <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
              </svg>
            </div>
            <MapContainer
              center={[site.coordinates[0], site.coordinates[1]]}
              zoom={13}
              style={{ height: '100%', width: '100%', zIndex: 10 }}
              zoomControl={false}
              attributionControl={false}
              dragging={false}
              scrollWheelZoom={false}
              doubleClickZoom={false}
              touchZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[site.coordinates[0], site.coordinates[1]]}
                icon={defaultIcon}
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteCard;
