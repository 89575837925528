import { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { SiteMap } from '@/types/SiteMap';

export const initSiteMapData: SiteMap = {
  id: '',
  name: '',
  userId: '',
  brainstormId: '',
  libraryId: '',
  sites: [],
  selectedSites: [],
};

export const useLoadSitemap = (initialData: SiteMap = initSiteMapData) => {
  const [sitemap, setSitemap] = useState<SiteMap>(initialData);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchSitemap = async (sitemapId: string) => {
    setLoading(true);
    try {
      console.log('Fetching sitemap with id:', sitemapId);
      const sitemapRef = doc(db, 'sitemaps', sitemapId);
      const sitemapSnap = await getDoc(sitemapRef);

      if (sitemapSnap.exists()) {
        const sitemapData = sitemapSnap.data() as SiteMap;
        const newSitemap = {
          ...initialData,
          ...sitemapData,
          id: sitemapSnap.id,
        };
        setSitemap(newSitemap);
        setIsLoaded(true);
        return newSitemap;
      }
    } catch (error) {
      console.error('Error fetching sitemap: ', error);
    } finally {
      setLoading(false);
    }
  };

  return { sitemap, loading, fetchSitemap, setSitemap, isLoaded };
};
