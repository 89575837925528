import React, { useEffect } from 'react';

import { getApp } from 'firebase/app';
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import LoginRightLayout from '../kadencebio/components/LoginRightLayout';

const schema = z
  .object({
    password: z.string().min(8, 'Password must be at least 8 characters.'),
    confirmPassword: z
      .string()
      .min(8, 'Password must be at least 8 characters.'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

type FormData = z.infer<typeof schema>;

function SetNewPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const verifyCode = async () => {
      if (!oobCode) {
        setError('password', {
          type: 'manual',
          message: 'Invalid or expired password reset code.',
        });
      } else {
        const auth = getAuth(getApp());
        try {
          await verifyPasswordResetCode(auth, oobCode);
        } catch (error) {
          setError('password', {
            type: 'manual',
            message: 'Invalid or expired password reset code.',
          });
        }
      }
    };

    verifyCode();
  }, [oobCode, setError]);

  const onSubmit = async (data: FormData) => {
    if (!oobCode) {
      setError('password', {
        type: 'manual',
        message: 'Invalid or expired password reset code.',
      });
      return;
    }

    const auth = getAuth(getApp());
    try {
      await confirmPasswordReset(auth, oobCode, data.password);
      alert('Your password has been successfully reset. You can now sign in.');
      navigate('/', { replace: true });
    } catch (error: any) {
      switch (error.code) {
        case 'auth/weak-password':
          setError('password', {
            type: 'manual',
            message: 'Password should be at least 6 characters.',
          });
          break;
        default:
          setError('password', {
            type: 'manual',
            message: 'An error occurred. Please try again later.',
          });
          break;
      }
    }
  };

  return (
    <div className="w-screen h-screen bg-[#1F1F23] flex justify-center items-center">
      <div className="flex items-center justify-center w-3/5 h-full">
        <div className="flex justify-center items-start flex-col gap-8 w-[350px]">
          <h1 className="text-white text-[45px] leading-[60px] font-medium">
            New password
          </h1>
          <p className="text-[#9491A7] leading-4">
            Set a new password for your account
          </p>
          <form
            className="flex flex-col w-full gap-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col items-center justify-center w-full gap-y-4">
              <div className="flex flex-col items-start justify-start w-full gap-y-1">
                <div className="w-full border border-[#676578] bg-[#2C2B32] rounded-2xl flex justify-start items-center py-2 px-4 gap-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 text-[#9491A7]"
                  >
                    <path d="M3 14.5A5.5 5.5 0 0 1 8.5 9h7a5.5 5.5 0 0 1 5.5 5.5v2a5.5 5.5 0 0 1-5.5 5.5h-7A5.5 5.5 0 0 1 3 16.5z" />
                    <path
                      strokeLinecap="round"
                      d="M7.5 9V5.375A3.375 3.375 0 0 1 10.875 2v0h2.302A3.323 3.323 0 0 1 16.5 5.323V9"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      d="M12 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 15v2"
                    />
                  </svg>
                  <div className="flex flex-col items-start justify-start">
                    <label className="text-xs text-[#9491A7]">Password</label>
                    <input
                      type="password"
                      className="p-0 text-xs font-medium text-white bg-transparent border-none focus:outline-none"
                      {...register('password')}
                    />
                  </div>
                </div>

                {errors.password && (
                  <p className="text-xs text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-y-1">
                <div className="w-full border border-[#676578] bg-[#2C2B32] rounded-2xl flex justify-start items-center py-2 px-4 gap-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 text-[#9491A7]"
                  >
                    <path d="M3 14.5A5.5 5.5 0 0 1 8.5 9h7a5.5 5.5 0 0 1 5.5 5.5v2a5.5 5.5 0 0 1-5.5 5.5h-7A5.5 5.5 0 0 1 3 16.5z" />
                    <path
                      strokeLinecap="round"
                      d="M7.5 9V5.375A3.375 3.375 0 0 1 10.875 2v0h2.302A3.323 3.323 0 0 1 16.5 5.323V9"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      d="M12 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 15v2"
                    />
                  </svg>
                  <div className="flex flex-col items-start justify-start">
                    <label className="text-xs text-[#9491A7]">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="p-0 text-xs font-medium text-white bg-transparent border-none focus:outline-none"
                      {...register('confirmPassword')}
                    />
                  </div>
                </div>

                {errors.confirmPassword && (
                  <p className="text-xs text-red-500">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-[#00C7A8] py-2 px-4 rounded-2xl w-full flex justify-center items-center gap-x-2 text-black hover:bg-[#00b89b] transition-colors"
            >
              Reset Password
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.4697 5.96967C12.7626 5.67678 13.2374 5.67678 13.5303 5.96967L19.5303 11.9697C19.8232 12.2626 19.8232 12.7374 19.5303 13.0303L13.5303 19.0303C13.2374 19.3232 12.7626 19.3232 12.4697 19.0303C12.1768 18.7374 12.1768 18.2626 12.4697 17.9697L17.1893 13.25L5 13.25C4.58579 13.25 4.25 12.9142 4.25 12.5C4.25 12.0858 4.58579 11.75 5 11.75L17.1893 11.75L12.4697 7.03033C12.1768 6.73744 12.1768 6.26256 12.4697 5.96967Z"
                  fill="black"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>

      <LoginRightLayout />
    </div>
  );
}

export default SetNewPasswordPage;
