import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { auth, db } from '@/core/setup_firebase';
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import { Brainstorm } from '@/types/Brainstorm';
import SideLabelView from './sideSections/SideLabelView';
import StudyCard from './components/StudyCard';
import { fetchSponsors } from '@/api/libraryApi';
import arrowTopRightCircleIcon from '@/images/arrow_top_right_circle.svg';

import brainstormIcon from '@/images/storm_icon.svg';
import libraryIcon from '@/images/library_icon.svg';
import mapIcon from '@/images/map_icon.svg';

import { useLoadBrainstorm } from '@/hooks/useLoadBrainstorm';
import { useLoadLibrary, initLibraryData } from '@/hooks/useLoadLibrary';
import NeurouteAnimatedSearching from './components/NeurouteAnimatedSearching';

const LibraryCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    library,
    setLibrary,
    fetchLibrary,
    isLoaded: libraryIsLoaded,
  } = useLoadLibrary();

  const {
    brainstorm,
    setBrainstorm,
    fetchBrainstorm,
    isLoaded: brainstormIsLoaded,
  } = useLoadBrainstorm();

  const [sponsorsIsLoaded, setSponsorsIsLoaded] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (id && id !== 'create') {
          const exists = await fetchLibrary(id);
          if (!exists) {
            navigate('/kadencebio/libraries');
            return;
          }
          // If library has a brainstormId, load the brainstorm data
          if (library.brainstormId) {
            fetchBrainstorm(library.brainstormId);
          }
        } else {
          const brainstormId = searchParams.get('brainstormId');
          if (brainstormId) {
            await fetchBrainstormAndSave(brainstormId);
          }
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [
    id,
    navigate,
    searchParams,
    fetchLibrary,
    fetchBrainstorm,
    library.brainstormId,
  ]);

  const fetchBrainstormAndSave = async (brainstormId: string) => {
    console.log('Starting fetchBrainstormAndSave');
    try {
      const brainstormRef = doc(db, 'brainstorms', brainstormId);
      const brainstormSnap = await getDoc(brainstormRef);

      if (brainstormSnap.exists()) {
        console.log('Brainstorm found:', brainstormSnap.data());
        const data = brainstormSnap.data() as Brainstorm;
        const brainstormData = { ...data, id: brainstormSnap.id };
        setBrainstorm(brainstormData);

        // First, fetch sponsors
        console.log('Fetching sponsors...');
        const sponsorsResponse = await fetchSponsors({
          condition: [brainstormData.indication],
          phase: brainstormData.phase,
          relevance: brainstormData.relevance,
          intervention_type: brainstormData.interventionType,
        });
        setSponsorsIsLoaded(true);
        console.log('Sponsors fetched:', sponsorsResponse);

        // Create new library with sponsors included
        const newLibrary = {
          ...initLibraryData,
          brainstormId: brainstormId,
          name: `${data.indication || ''} - ${data.interventionType || ''}`.trim(),
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
          sponsors: sponsorsResponse.sponsors,
        };

        console.log('Creating new library:', newLibrary);
        // Save and redirect
        const newLibraryRef = await addDoc(
          collection(db, 'libraries'),
          newLibrary,
        );
        console.log('Library created with ID:', newLibraryRef.id);
        navigate(`/kadencebio/library/edit/${newLibraryRef.id}`);
      } else {
        console.log('Brainstorm not found');
      }
    } catch (error) {
      console.error('Error in fetchBrainstormAndSave: ', error);
    } finally {
    }
  };

  const saveLibrary = async () => {
    try {
      if (id) {
        await setDoc(doc(db, 'libraries', id), library);
      } else {
        const newLibraryRef = await addDoc(collection(db, 'libraries'), {
          ...library,
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
        });
        navigate(`/kadencebio/library/edit/${newLibraryRef.id}`);
      }
      console.log('Library saved successfully');
    } catch (error) {
      console.error('Error saving library: ', error);
    }
  };

  const handleSponsorSelect = (sponsorName: string) => {
    if (!library.selectedSponsors.includes(sponsorName)) {
      setLibrary((prev) => ({
        ...prev,
        selectedSponsors: [...prev.selectedSponsors, sponsorName],
      }));
    }
  };

  const handleSponsorDeselect = (sponsorName: string) => {
    setLibrary((prev) => ({
      ...prev,
      selectedSponsors: prev.selectedSponsors.filter(
        (name) => name !== sponsorName,
      ),
    }));
  };

  const renderLeftColumn = () => {
    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        {brainstorm && (
          <>
            <SideLabelView
              brainstorm={brainstorm}
              field="indication"
              label="Indication"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="interventionType"
              label="Intervention"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="relevance"
              label="Relevance"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="phase"
              label="Phase"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="patientCount"
              label="Patient No."
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="inclusionCriteria"
              label="I/C"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="exclusionCriteria"
              label="E/C"
            />
            <SideLabelView
              brainstorm={brainstorm}
              field="timing"
              label="Timing"
            />
          </>
        )}
      </div>
    );
  };

  const renderMainSection = () => {
    return (
      <div className="overflow-y-auto max-h-[80vh] mt-4 py-2 pr-6">
        {library.sponsors.length < 1 ? (
          <div className="text-white">Loading sponsors...</div>
        ) : library.sponsors?.length > 0 ? (
          <div className="mb-8">
            <h1 className="text-white text-4xl mb-4">
              {library.sponsors.length} Sponsors with a completed{' '}
              {brainstorm?.phase} in {brainstorm?.indication}
            </h1>
            <p className="text-[#9491a7] mb-8">
              Build maps of the sites used by relevant sponsors by selecting
              from the list below
            </p>

            <div className="space-y-6">
              {library.sponsors.map((sponsor) => (
                <div
                  key={sponsor.sponsor_name}
                  className="bg-[#2A2A2E] p-6 rounded-lg cursor-pointer hover:bg-[#353539] transition-colors"
                  onClick={() => handleSponsorSelect(sponsor.sponsor_name)}
                >
                  <div className="flex justify-between items-start">
                    <div className="w-1/3 pr-6 border-r border-[#4B4A55]">
                      <h3 className="text-white text-4xl mb-4 break-words">
                        {sponsor.sponsor_name}
                      </h3>
                      <p className="text-[#9491a7] text-sm">
                        <span className="text-white">
                          {sponsor.total_num_studies} Total Studies.
                        </span>{' '}
                        {/* 6 Breast Cancer, 3 Parkinson's Disease, 4 Alzheimer's
                        Disease, 1 in Premature Ejaculation. */}
                      </p>
                    </div>

                    <div className="w-2/3 relative ml-2">
                      {sponsor.studies.map((study, studyIndex) => (
                        <StudyCard
                          key={studyIndex}
                          study={study}
                          phase={brainstorm?.phase}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className="overflow-y-auto max-h-[calc(100vh-60px)] pl-6">
        <div className="p-6 rounded-lg">
          <h2 className="text-[#9491a7] text-2xl mb-4">Site Maps</h2>
          <div className="flex items-start space-x-3 mb-4">
            <div className="w-8 h-8 flex items-center justify-center">
              <img
                src={arrowTopRightCircleIcon}
                alt="Open Map"
                className="w-4 h-4 text-[#9491a7] mb-1"
              />
            </div>
            <div>
              <h3 className="text-[#9491a7] text-lg mb-1">
                All Relevant Sites Used By
              </h3>
              {library.selectedSponsors.length === 0 ? (
                <p className="text-[#9491a7] text-sm italic">
                  Select 3 sponsors then press arrow to open map
                </p>
              ) : (
                <div className="space-y-2 mt-3">
                  {library.selectedSponsors.map((sponsorName) => (
                    <div
                      key={sponsorName}
                      className="text-[#9491a7] pl-2 hover:text-white cursor-pointer transition-colors"
                      onClick={() => handleSponsorDeselect(sponsorName)}
                    >
                      {sponsorName}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleBuildSiteMap = async () => {
    await saveLibrary();
    navigate(`/kadencebio/sitemap/create?libraryId=${library.id}`);
  };

  if (
    (!libraryIsLoaded && !brainstormIsLoaded && !sponsorsIsLoaded) ||
    searchParams.get('brainstormId')
  ) {
    return (
      <ProjectLayout title="KadenceBio">
        <div className="flex items-center justify-center h-screen">
          <NeurouteAnimatedSearching />
        </div>
      </ProjectLayout>
    );
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="flex-grow flex flex-col p-0 min-h-screen relative">
        <div className="flex p-0 flex-row w-full">
          {renderLeftColumn()}

          <div className="flex flex-col w-3/4">
            <div className="h-[100px] pr-6 flex items-center gap-4">
              <button
                className="flex items-center gap-2 bg-[#2D2C36] text-white px-4 py-2 rounded-lg"
                onClick={() =>
                  brainstorm?.id &&
                  navigate(`/kadencebio/brainstorm/edit/${brainstorm.id}`)
                }
              >
                <span className="text-[#9491a7]">
                  <img
                    src={libraryIcon}
                    alt="Library"
                    className="w-4 h-4 [filter:invert(60%)_sepia(11%)_saturate(709%)_hue-rotate(208deg)_brightness(87%)_contrast(86%)]"
                  />
                </span>
                <span className="text-[#9491a7]">
                  <img
                    src={brainstormIcon}
                    alt="Brainstorm"
                    className="w-4 h-4 [filter:invert(61%)_sepia(13%)_saturate(368%)_hue-rotate(208deg)_brightness(87%)_contrast(87%)]"
                  />
                </span>
                <span className="text-[#938EA4] font-medium text-[18px]">
                  I want to solve {brainstorm?.indication}
                </span>
              </button>
              <button
                className="flex items-center gap-2 border-2 border-[#2A2A2E] text-white px-4 py-2 rounded-lg"
                onClick={saveLibrary}
                disabled={!library.name || !library.brainstormId}
              >
                <span className="text-[#938EA4] font-medium text-[18px]">
                  Make new Library
                </span>
                <span className="text-[#9491a7]">
                  <span className="flex items-center gap-2">
                    <img
                      src={libraryIcon}
                      alt="Library"
                      className="w-4 h-4 [filter:invert(60%)_sepia(11%)_saturate(709%)_hue-rotate(208deg)_brightness(87%)_contrast(86%)]"
                    />
                    <span>→</span>
                    <span>
                      <img
                        src={brainstormIcon}
                        alt="Brainstorm"
                        className="w-4 h-4 [filter:invert(60%)_sepia(11%)_saturate(709%)_hue-rotate(208deg)_brightness(87%)_contrast(86%)]"
                      />
                    </span>
                  </span>
                </span>
              </button>
            </div>
            {renderMainSection()}
          </div>
          <div className="flex flex-col w-1/4">
            <div className="h-[60px] py-2 pl-6"></div>
            {renderRightSection()}
          </div>
        </div>

        {/* New floating button */}
        {library.selectedSponsors.length > 0 && (
          <button
            className="fixed bottom-8 right-8 bg-[#00C7A8]/10 text-[#00C7A8] px-[22.4px] py-[11.2px] rounded-[30px] flex items-center gap-[11.2px] hover:bg-[#00C7A8]/20 transition-colors"
            onClick={handleBuildSiteMap}
          >
            <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em]">
              Build Site Map
            </span>
            <img
              src={mapIcon}
              alt="Map"
              className="w-[24px] h-[24px] [filter:invert(72%)_sepia(40%)_saturate(463%)_hue-rotate(118deg)_brightness(95%)_contrast(101%)]"
            />
          </button>
        )}
      </main>
    </ProjectLayout>
  );
};

export default LibraryCreateEditPage;
