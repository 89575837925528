import { API_BASE_URL } from '@/api/config';
import { useState, useCallback } from 'react';

interface SiteRandParams {
  condition: string | string[];
  brainstormId?: string;
  intervention_type: string;
  relevance: string;
  phase: string;
  site_name: string;
}

interface SiteRandResponse {
  site_rand: number;
}

export const useLoadSiteRand = () => {
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchSiteRand = useCallback(async (params: SiteRandParams) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        condition: Array.isArray(params.condition)
          ? params.condition.join(',')
          : params.condition,
        intervention_type: params.intervention_type,
        relevance: params.relevance,
        phase: params.phase,
        site_name: params.site_name,
      });

      const response = await fetch(
        `${API_BASE_URL}/routes/get-site-rand?${queryParams}`,
      );
      if (!response.ok) {
        throw new Error('Failed to fetch site rand');
      }

      const data: SiteRandResponse = await response.json();
      setIsLoaded(true);
      return data.site_rand;
    } catch (error) {
      console.error('Error fetching site rand:', error);
      return 30; // fallback default value
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, fetchSiteRand, isLoaded };
};
