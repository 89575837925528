import React from 'react';
import { OutcomeMeasure } from '@/types/Brainstorm';
import { StepSectionProps } from '../stepSections/StepSectionProps';

const OutcomesCard: React.FC<{ outcome: OutcomeMeasure }> = ({ outcome }) => (
  <div className="mb-4 p-4 rounded hover:bg-[#D9D9D9] hover:bg-opacity-10 flex">
    <div className="flex-shrink-0 text-sm text-white mr-2">
      <p>O</p>
      <p>D</p>
      <p>T</p>
    </div>
    <div className="text-sm text-white">
      <p>{outcome.O}</p>
      <p>{outcome.D}</p>
      <p>{outcome.T}</p>
    </div>
  </div>
);

const OutcomesListSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleOutcomeClick = (outcomeId: string) => {
    const updatedOutcomes = brainstorm.outcomes?.includes(outcomeId)
      ? brainstorm.outcomes.filter((item) => item !== outcomeId)
      : [...(brainstorm.outcomes || []), outcomeId];

    setBrainstorm({
      ...brainstorm,
      outcomes: updatedOutcomes,
    });
  };

  return (
    <div className="mb-8">
      <h2 className="text-white text-2xl font-medium mb-4">Outcomes</h2>
      <p className="text-[#9491a7] mb-4">
        All outcomes across relevant studies related to {brainstorm.indication}
      </p>
      <p className="mb-4 text-sm text-white">Primary Outcome Measures</p>
      <ul className="pl-0">
        {brainstorm.availableOutcomes?.map((outcome, index) => (
          <li
            key={index}
            className="cursor-pointer list-none"
            onClick={() => handleOutcomeClick(outcome.id)}
          >
            <OutcomesCard outcome={outcome} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OutcomesListSection;
