import { createBrowserRouter } from 'react-router-dom';

import AuthGuard from '@/pages/auth/AuthGuard';
import ForgotPasswordPage from '@/pages/auth/ForgotPasswordPage';
import ProfilePage from '@/pages/auth/ProfilePage';
import SignOutPage from '@/pages/auth/SignOutPage';
import CommentManagementPage
  from '@/pages/comment-management/CommentManagementPage';
// community home page with graph
import InitiativeGraphHomePage
  from '@/pages/community-home/InitiativeGraphHomePage';
// flow 2 - individual page
import IndividualPage from '@/pages/community-individual/IndividualPage';
import AddExclusionCriteriaPage
  from '@/pages/create-project/AddExclusionCriteriaPage';
import AddInclusionCriteriaPage
  from '@/pages/create-project/AddInclusionCriteriaPage';
//
import CreateStudyPage from '@/pages/create-project/CreateProjectPage';
import CreateScenarioFormPage
  from '@/pages/create-project/CreateScenarioFormPage';
import CreateStudyFormPage from '@/pages/create-project/CreateStudyFormPage';
import ProjectsPage from '@/pages/create-project/ProjectsPage';
import ResearchList from '@/pages/dashboard/components/ResearchList';
//
import DashboardV2Page from '@/pages/dashboard/DashboardV2Page';
import ErrorPage from '@/pages/error/ErrorPage';
// New BuildProfile - location input with Google Maps
import LocationPage from '@/pages/flow-1-wireframes/LocationPage';
// New BuildProfile - flow 1 wireframes
import Flow1Step1Page from '@/pages/flow-1-wireframes/Step1Page';
import Flow1Step2Page from '@/pages/flow-1-wireframes/Step2Page';
import Flow1Step3Page from '@/pages/flow-1-wireframes/Step3Page';
// flow 3 wireframes
import Flow3Step2Page from '@/pages/flow-3-wireframes/Step2Page';
import Flow3Step3Page from '@/pages/flow-3-wireframes/Step3Page';
import Flow3Step4Page from '@/pages/flow-3-wireframes/Step4Page';
import Flow3Step5Page from '@/pages/flow-3-wireframes/Step5Page';
import SubscriptionModal
  from '@/pages/initiatives/components/SubscriptionModal';
import InitiativeCreateOrEditPage
  from '@/pages/initiatives/InitiativeCreateOrEditPage';
// flow 5 - create
import InitiativesPage from '@/pages/initiatives/InitiativesPage';
import InitiativeViewPage from '@/pages/initiatives/InitiativeViewPage';
import ForgotPasswordPageV2 from '@/pages/login-flow/ForgotPasswordPage';
import SetNewPasswordPage from '@/pages/login-flow/SetNewPasswordPage';
import SignInPhoneOrEmailPage from '@/pages/login-flow/SignInPhoneOrEmailPage';
import MockMLPage from '@/pages/mock-ml/MockMLPage';
import BuildProfileGraphPageStep0
  from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep0';
import BuildProfileGraphPageStep1
  from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep1';
import BuildProfileGraphStep2Page
  from '@/pages/poc-build-profile-1/BuildProfileGraphStep2Page';
import WaitingForCollision
  from '@/pages/poc-build-profile-1/WaitingForCollisionPage';
import UserManagementPage from '@/pages/user-management/UserManagementPage';

import App from '../App';
// flow 4
import Flow3Step6and7Page from '../pages/flow-3-wireframes/Step6and7Page';
import KadenceBioRoutes from './routes/KadenceBioRoutes';

const pub = (path: string, Component: React.FC) => ({
  path,
  element: <Component />,
  errorElement: <ErrorPage />,
});

const guarded = (path: string, Component: React.FC) => ({
  path,
  element: (
    <AuthGuard>
      <Component />
    </AuthGuard>
  ),
  errorElement: <ErrorPage />,
});

export const router = createBrowserRouter([
  pub('/login', SignInPhoneOrEmailPage),
  pub('/login/phone-or-email', SignInPhoneOrEmailPage),
  pub('/login/forgot-password', ForgotPasswordPageV2),
  pub('/login/set-new-password', SetNewPasswordPage),
  // pub('/login', LoginOptionsPage),
  // pub('/sign-up/birthday', SignUpBirthdayPage),
  // pub('/sign-up/phone-or-email', SignUpPhoneOrEmailPage),
  // pub('/sign-up/create-password', SignUpCreatePassword),
  // pub('/sign-up', SignUpPage),
  pub('/sign-up', SignInPhoneOrEmailPage),
  pub('/sign-out', SignOutPage),
  pub('/forgot-password', ForgotPasswordPage),
  pub('/graph', InitiativeGraphHomePage),
  pub('/user-management', UserManagementPage),
  pub('/comment-management', CommentManagementPage),
  pub('/poc-build-profile-1/step-0', BuildProfileGraphPageStep0),
  pub('/poc-build-profile-1/step-1', BuildProfileGraphPageStep1),
  pub('/waitlist', BuildProfileGraphStep2Page),
  pub('/waiting-for-collision', WaitingForCollision),

  pub('/', SignInPhoneOrEmailPage),
  // pub('/', LoginOptionsPage),
  // pub('/', InitiativeGraphHomePage),
  guarded('/dashboard/:id', DashboardV2Page),
  guarded('/dashboard_old', () => <App old />),
  guarded('/research_list', ResearchList),

  guarded('/profile', ProfilePage),
  pub('/subscription', SubscriptionModal),
  pub('/mock-ml-call', MockMLPage),
  guarded('/projects', ProjectsPage),
  guarded('/wireframes/create-project', CreateStudyPage),
  guarded('/wireframes/create-project-form', CreateStudyFormPage),
  guarded('/wireframes/create-scenario-form', CreateScenarioFormPage),
  guarded('/wireframes/add-inclusion-criteria-page', AddInclusionCriteriaPage),
  guarded('/wireframes/add-exclusion-criteria-page', AddExclusionCriteriaPage),
  guarded('/flow-3-wireframes/step-2', Flow3Step2Page),
  guarded('/flow-1-wireframes/location', LocationPage),
  guarded('/flow-1-wireframes/step-1', Flow1Step1Page),
  guarded('/flow-1-wireframes/step-2', Flow1Step2Page),
  guarded('/flow-1-wireframes/step-3', Flow1Step3Page),
  guarded('/flow-3-wireframes/step-3', Flow3Step3Page),
  guarded('/flow-3-wireframes/step-4', Flow3Step4Page),
  guarded('/flow-3-wireframes/step-5', Flow3Step5Page),
  guarded('/flow-3-wireframes/step-6-7/:projectId?', Flow3Step6and7Page),
  guarded('/community/individual', IndividualPage),
  guarded('/initiatives', InitiativesPage),
  guarded('/initiative/create', InitiativeCreateOrEditPage),
  guarded('/initiative/edit/:id', InitiativeCreateOrEditPage),
  guarded('/initiative/:id', InitiativeViewPage),
  guarded('/initiatives/:id', InitiativeViewPage),
  ...KadenceBioRoutes,
]);
