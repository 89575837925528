import React, { useEffect, useState } from 'react';

import { isThisMonth, isThisWeek, isToday, isYesterday } from 'date-fns';

import { useAuth } from '@/pages/auth/AuthContext';
import { Brainstorm } from '@/types/Brainstorm';
import { getBrainstorms, getDisplayName } from '@/utils/brainstormUtils';

import BrainstormNavItem from './BrainstormNavItem';

export interface BrainstormItem {
  id: string;
  title: string;
  link: string;
  hasLibrary?: boolean;
  hasSiteMap?: boolean;
  hasRoute?: boolean;
}

interface OrganizedBrainstorms {
  today: BrainstormItem[];
  yesterday: BrainstormItem[];
  thisWeek: BrainstormItem[];
  thisMonth: BrainstormItem[];
  older: BrainstormItem[];
}
interface BrainstormContainerProps {
  searchQuery: string;
}

const BrainstormContainer: React.FC<BrainstormContainerProps> = ({
  searchQuery,
}) => {
  const [brainstorms, setBrainstorms] = useState<Brainstorm[]>([]);
  const [pinnedBrainstorms, setPinnedBrainstorms] = useState<BrainstormItem[]>(
    [],
  );
  const brainstormRef = React.useRef<HTMLDivElement>(null);

  const [organizedBrainstorms, setOrganizedBrainstorms] =
    useState<OrganizedBrainstorms>({
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      older: [],
    });

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchBrainstorms = async () => {
      if (currentUser?.id) {
        const brainstormList = await getBrainstorms(currentUser.id);
        setBrainstorms(brainstormList);

        // Organize brainstorms by time periods
        const organized = brainstormList.reduce<OrganizedBrainstorms>(
          (acc, brainstorm) => {
            const item: BrainstormItem = {
              id: brainstorm.id,
              title: getDisplayName(brainstorm),
              link: `/kadencebio/brainstorm/edit/${brainstorm.id}`,
              hasLibrary: brainstorm.hasLibrary,
              hasSiteMap: brainstorm.hasSiteMap,
              hasRoute: brainstorm.hasRoute,
            };

            if (!brainstorm?.createdAt) {
              acc.older.push(item);
              return acc;
            }

            try {
              // Convert Firestore timestamp to Date
              const createdDate = brainstorm.createdAt.toDate();

              console.log('Processing brainstorm:', {
                id: brainstorm.id,
                createdAt: createdDate,
                title: getDisplayName(brainstorm),
                hasLibrary: brainstorm.hasLibrary,
                hasSiteMap: brainstorm.hasSiteMap,
                hasRoute: brainstorm.hasRoute,
              });

              if (isToday(createdDate)) {
                acc.today.push(item);
              } else if (isYesterday(createdDate)) {
                acc.yesterday.push(item);
              } else if (
                isThisWeek(createdDate) &&
                !isToday(createdDate) &&
                !isYesterday(createdDate)
              ) {
                acc.thisWeek.push(item);
              } else if (isThisMonth(createdDate) && !isThisWeek(createdDate)) {
                acc.thisMonth.push(item);
              } else {
                acc.older.push(item);
              }
            } catch (error) {
              console.error(
                'Error processing date for brainstorm:',
                brainstorm.id,
                error,
              );
              acc.older.push(item);
            }

            return acc;
          },
          {
            today: [],
            yesterday: [],
            thisWeek: [],
            thisMonth: [],
            older: [],
          },
        );
        console.log('All brainstorms:', brainstormList);

        console.log('Organized brainstorms:', organized);
        setOrganizedBrainstorms(organized);
      }
    };

    fetchBrainstorms();
  }, [currentUser?.id]);

  const filterBrainstorms = (
    items: BrainstormItem[],
    query: string,
  ): BrainstormItem[] => {
    if (!query) return items;
    if (!Array.isArray(items)) return [];

    return items.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase()),
    );
  };

  // Filtered brainstorms
  const filteredBrainstorms = {
    today: filterBrainstorms(organizedBrainstorms.today, searchQuery),
    yesterday: filterBrainstorms(organizedBrainstorms.yesterday, searchQuery),
    thisWeek: filterBrainstorms(organizedBrainstorms.thisWeek, searchQuery),
    thisMonth: filterBrainstorms(organizedBrainstorms.thisMonth, searchQuery),
    older: filterBrainstorms(organizedBrainstorms.older, searchQuery),
  };

  if (!currentUser) return null;

  return (
    <div className="mt-4">
      {/* Brainstorms history list container NEED HEIGHT FIX AND SCROLL FIX*/}
      <div className="max-h-[calc(100dvh-470px)] overflow-x-clip overflow-y-auto scrollbar-thin scrollbar-track-[#403f4d] scrollbar-thumb-[#9492b2]">
        {/* Pinned  */}
        {/* <div>
          <span className="font-medium font-satoshi text-xs text-[#5a5873]">
            Pinned
          </span>
          {
             brainstorms
            .filter((brainstorm) => brainstorm.name.toLowerCase().includes(searchQuery.toLowerCase()))
              .filter((brainstorm) => brainstorm.pinned)
              .map((brainstorm, index) => (
                <div key={index} className="flex justify-between pt-2">
                  <div className="relative flex pl-2 gap-x-1">
                    <div className="w-2 h-2 absolute top-1.5 rounded-full bg-[#7a788a]" />
                    <ul className="pl-6">
                      <li className="text-sm font-satoshi font-normal text-[#9492b2] truncate line-clamp-1">
                        {brainstorm.name}
                      </li>
                    </ul>
                  </div>
                  <button>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3337 5.33268L10.667 2.66602L4.66699 5.99935L10.0003 11.3327L13.3337 5.33268Z"
                        fill="white"
                        fillOpacity="0.15"
                      />
                      <path
                        d="M7.33366 8.66598L2.66699 13.3326M10.667 2.66602L13.3336 5.33268L10.0003 11.3327L4.66697 5.99935L10.667 2.66602Z"
                        stroke="#9492B2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              )) 
          }
        </div>  */}

        <div className="pt-2">
          <span className="font-medium font-satoshi text-xs text-[#5a5873]">
            Today
          </span>
          {
            //displaying the brainstorms for today
            filteredBrainstorms.today.map((brainstorm, index) => (
              <div key={index} className="flex flex-col pt-2">
                <BrainstormNavItem brainstorm={brainstorm} />
              </div>
            ))
          }
        </div>

        {/* Yesterday */}
        {filteredBrainstorms.yesterday.length > 0 && (
          <div>
            <span className="font-medium font-satoshi text-xs text-[#5a5873]">
              Yesterday
            </span>
            {filteredBrainstorms.yesterday.map((brainstorm, index) => (
              <div key={index} className="flex flex-col pt-2">
                <BrainstormNavItem brainstorm={brainstorm} />
              </div>
            ))}
          </div>
        )}

        {/* Last Week */}

        {filteredBrainstorms.thisMonth.length > 0 && (
          <div>
            <span className="font-medium font-satoshi text-xs text-[#5a5873]">
              Last week
            </span>
            {filteredBrainstorms.thisMonth &&
              filteredBrainstorms.thisMonth.length > 0 &&
              filteredBrainstorms.thisMonth.map((brainstorm, index) => (
                <div key={index} className="flex flex-col pt-2">
                  <BrainstormNavItem brainstorm={brainstorm} />
                </div>
              ))}
          </div>
        )}

        {/* Older */}
        <div>
          <span className="font-medium font-satoshi text-xs text-[#5a5873]">
            Older
          </span>
          {filteredBrainstorms.older.map((brainstorm, index) => (
            <div key={index} className="flex flex-col pt-2">
              <BrainstormNavItem brainstorm={brainstorm} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrainstormContainer;
