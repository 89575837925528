import React, { useEffect, useRef } from 'react';

import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchSimilarConditions } from '@/api/brainstormApi';
import { auth, db } from '@/core/setup_firebase';
import {
  initBrainstormData,
  useLoadBrainstorm,
} from '@/hooks/useLoadBrainstorm';
import ArrowRightSvg from '@/images/arrow_right.svg';

import NeurouteAnimatedSearching from './components/NeurouteAnimatedSearching';
import { ProjectLayout } from './components/ProjectsLayout';
import BrainstormDoneSection from './sections/BrainstormDoneSection';
import ExclusionCriteriaListSection from './sections/ExclusionCriteriaListSection';
// Import types
import HeaderTitle from './sections/HeaderTitle';
import InclusionCriteriaListSection from './sections/InclusionCriteriaListSection';
import OutcomesListSection from './sections/OutcomesListSection';
import SearchAndDisplay from './sections/SearchAndDisplay';
// Main Section Components
import Step1Indication from './sections/Step1Indication';
// Left Section Components
import SideLabel from './sideSections/SideLabel';
import ExclusionCriteriaSection from './stepSections/ExclusionCriteriaSection';
import InclusionCriteriaSection from './stepSections/InclusionCriteriaSection';
// Attempt number 987654321
import InterventionTypeSection from './stepSections/InterventionTypeSection';
import OutcomesSection from './stepSections/OutcomesSection';
import PatientCountSection from './stepSections/PatientCountSection';
import PhaseSection from './stepSections/PhaseSection';
import RelevanceSection from './stepSections/RelevanceSection';
import TimingSection from './stepSections/TimingSection';

const featureFlags = {
  outcomes: process.env.REACT_APP_DEV_MODE === 'true' ? true : false,
};

const BrainstormCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    brainstorm,
    setBrainstorm,
    loading: brainstormLoading,
    fetchBrainstorm,
  } = useLoadBrainstorm(initBrainstormData);

  // Add ref for the main content area
  const mainContentRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom smoothly
  const scrollToBottom = () => {
    if (mainContentRef.current) {
      const scrollContainer = mainContentRef.current;
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    const checkAuth = async () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (!isSubscribed) return;

        if (user) {
          if (id && id !== 'create') {
            const exists = await fetchBrainstorm(id);
            if (!exists && isSubscribed) {
              navigate('/kadencebio/brainstorms');
            }
          }
        } else {
          navigate('/login');
        }
      });

      return unsubscribe;
    };

    const unsubscribe = checkAuth();
    return () => {
      isSubscribed = false;
      unsubscribe.then((fn) => fn());
    };
  }, [id, navigate, fetchBrainstorm]);

  // Helper function to check if a string matches a wildcard pattern
  const matchesWildcard = (str: string, pattern: string) => {
    if (pattern.endsWith('*')) {
      const prefix = pattern.slice(0, -1);
      return str.startsWith(prefix);
    }
    return str === pattern;
  };

  // Main function to check current step
  const isCurrentStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      return field.some((f) => matchesWildcard(brainstorm.currentStep, f));
    }
    return matchesWildcard(brainstorm.currentStep, field);
  };

  const isSection = (field: string) => {
    // Check if current step matches the pattern
    const isCurrentStepMatch = isCurrentStep(`${field}*`);

    // Check if any completed step starts with the field
    const hasCompletedStep = brainstorm.completedSteps.some((step) =>
      step.startsWith(field),
    );

    return isCurrentStepMatch || hasCompletedStep;
  };

  const setCurrentStep = (field: string) => {
    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev, currentStep: field };
      return updatedBrainstorm;
    });
  };
  const nextStep = (nextStep: string, alsoComplete?: string) => {
    setBrainstorm((prev) => {
      // set the next step as the current step
      const { currentStep: prevStep } = prev;
      const updatedBrainstorm = { ...prev, currentStep: nextStep };
      // add current step to completed steps
      if (!updatedBrainstorm.completedSteps.includes(prevStep)) {
        updatedBrainstorm.completedSteps = [
          ...updatedBrainstorm.completedSteps,
          prevStep,
        ];
      }
      // add additional step to completed steps if provided
      if (alsoComplete) {
        if (!updatedBrainstorm.completedSteps.includes(alsoComplete)) {
          updatedBrainstorm.completedSteps = [
            ...updatedBrainstorm.completedSteps,
            alsoComplete,
          ];
        }
      }
      return updatedBrainstorm;
    });

    // Add a small delay to ensure the new content is rendered before scrolling
    setTimeout(scrollToBottom, 100);
  };

  const completeStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      field.forEach((f) => completeStep(f));
      return;
    }

    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev };
      // Add the field to completedSteps if it's not already there
      if (!updatedBrainstorm.completedSteps.includes(field)) {
        updatedBrainstorm.completedSteps = [
          ...updatedBrainstorm.completedSteps,
          field,
        ];
      }
      return updatedBrainstorm;
    });

    // Add a small delay to ensure the new content is rendered before scrolling
    setTimeout(scrollToBottom, 100);
  };

  const removeStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      field.forEach((f) => removeStep(f));
      return;
    }

    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev };

      // Check if wildcard is used (ends with *)
      if (field.endsWith('*')) {
        const prefix = field.slice(0, -1); // Remove the * character
        // Filter out all steps that start with the prefix
        updatedBrainstorm.completedSteps =
          updatedBrainstorm.completedSteps.filter(
            (step) => !step.startsWith(prefix),
          );
      } else {
        // Regular exact match removal
        updatedBrainstorm.completedSteps =
          updatedBrainstorm.completedSteps.filter((step) => step !== field);
      }

      return updatedBrainstorm;
    });
  };

  const saveBrainstorm = async () => {
    // Create updated brainstorm with completed step, ensuring no duplicates
    const updatedBrainstorm = {
      ...brainstorm,
      completedSteps: Array.from(
        new Set([...brainstorm.completedSteps, 'brainstorm.done']),
      ),
    };

    try {
      if (id) {
        await setDoc(doc(db, 'brainstorms', id), updatedBrainstorm);
      } else {
        const newBrainstormRef = await addDoc(collection(db, 'brainstorms'), {
          ...updatedBrainstorm,
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
        });
        navigate(`/kadencebio/brainstorm/edit/${newBrainstormRef.id}`);
      }

      // Update state after successful save
      completeStep('brainstorm.done');
      console.log('Brainstorm saved successfully');
    } catch (error) {
      console.error('Error saving brainstorm: ', error);
    }
  };

  const notCompleted = (field: string) =>
    !brainstorm.completedSteps.includes(field);
  const hasStep = (field: string) => brainstorm.completedSteps.includes(field);
  const anyStep = (fields: string[]) => fields.some((field) => hasStep(field));
  const andStep = (fields: string[]) => fields.every((field) => hasStep(field));

  console.log('current', brainstorm.currentStep);
  console.log('completed', brainstorm.completedSteps);

  const handleBuildLibrary = async () => {
    try {
      if (id) {
        await setDoc(doc(db, 'brainstorms', id), brainstorm);
        navigate(`/kadencebio/library/create/?brainstormId=${brainstorm.id}`);
      } else {
        const newBrainstormRef = await addDoc(collection(db, 'brainstorms'), {
          ...brainstorm,
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
        });
        navigate(
          `/kadencebio/library/create/?brainstormId=${newBrainstormRef.id}`,
        );
      }
      console.log('Brainstorm saved successfully');
    } catch (error) {
      console.error('Error saving brainstorm: ', error);
    }
  };

  const renderLeftColumn = () => {
    if (isCurrentStep('indication.edit')) return null;

    const sideProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
    };

    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        <SideLabel label="Indication" field="indication" {...sideProps} />
        <SideLabel
          label="Intervention"
          field="interventionType"
          {...sideProps}
        />
        <SideLabel label="Relevance" field="relevance" {...sideProps} />
        <SideLabel label="Phase" field="phase" {...sideProps} />
        <SideLabel label="Patient No." field="patientCount" {...sideProps} />
        <SideLabel label="I/C" field="inclusionCriteria" {...sideProps} />
        <SideLabel label="E/C" field="exclusionCriteria" {...sideProps} />
        {featureFlags.outcomes && (
          <SideLabel label="Outcomes" field="outcomes" {...sideProps} />
        )}
        <SideLabel label="Timing" field="timing" {...sideProps} />
      </div>
    );
  };

  const renderMainSection = () => {
    if (isCurrentStep('indication.edit')) {
      return (
        <Step1Indication
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          onNextStep={() => {
            setBrainstorm((prev) => ({
              ...prev,
              similarConditions: [],
              similarConditionsCount: 0,
            }));
            nextStep('indication.search');
          }}
        />
      );
    }

    const sectionProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
      featureFlags,
    };

    return (
      <div className="flex flex-col w-3/5">
        <div
          ref={mainContentRef}
          className="overflow-y-auto max-h-[80vh] mt-4 py-2 pr-6"
        >
          {/* SECTION INDICATION */}
          {hasStep('indication.edit') && (
            <HeaderTitle
              prefix="I want to help patients with"
              value={brainstorm.indication}
            />
          )}

          {(isCurrentStep('indication.search') ||
            hasStep('indication.search')) && (
            <SearchAndDisplay
              brainstorm={brainstorm}
              setBrainstorm={setBrainstorm}
              query={async () => {
                if (brainstorm.similarConditions.length > 0) {
                  return;
                }
                const data = await fetchSimilarConditions(
                  brainstorm.indicationFreeText,
                );
                setBrainstorm({
                  ...brainstorm,
                  similarConditions: data,
                  similarConditionsCount: data.length,
                });
              }}
              searchText={`searching for all the ways people have listed studies in ${brainstorm.indication}`}
              resultText={`It turns out there are ${brainstorm.similarConditionsCount} other ways to say ${brainstorm.indication} so we've grouped those together for you.`}
              onNextStep={() =>
                nextStep('interventionType.freeTextEdit', 'indication')
              }
            />
          )}

          {/* SECTION INTERVENTION TYPE */}
          <InterventionTypeSection {...sectionProps} />

          {/* SECTION RELEVANCE */}
          <RelevanceSection {...sectionProps} />

          {/* SECTION PHASE */}
          <PhaseSection {...sectionProps} />

          {/* SECTION PATIENT COUNT */}
          <PatientCountSection {...sectionProps} />

          {/* SECTION INCLUSION CRITERIA */}
          <InclusionCriteriaSection {...sectionProps} />

          {/* SECTION EXCLUSION CRITERIA */}
          <ExclusionCriteriaSection {...sectionProps} />

          {/* SECTION OUTCOMES */}
          {featureFlags.outcomes && <OutcomesSection {...sectionProps} />}

          {/* SECTION TIMELINE */}
          <TimingSection {...sectionProps} saveBrainstorm={saveBrainstorm} />

          {/* SECTION EDIT OR NEW */}
          {(isCurrentStep('brainstorm.done') ||
            hasStep('brainstorm.done') ||
            hasStep('timing.correct')) && (
            <div className="flex justify-between mt-8">
              {/* <button
                onClick={async () => {
                  await saveBrainstorm();
                }}
                className="flex items-center justify-center px-2 gap-2 h-14 rounded-full bg-[#00C7A8]"
              >
                <span className="pl-2 text-lg font-medium text-white">
                  Edit my Brainstorm
                </span>
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#009780]">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.4697 6.29584C12.7626 6.00295 13.2374 6.00295 13.5303 6.29584L19.5303 12.2958C19.8232 12.5887 19.8232 13.0636 19.5303 13.3565L13.5303 19.3565C13.2374 19.6494 12.7626 19.6494 12.4697 19.3565C12.1768 19.0636 12.1768 18.5887 12.4697 18.2958L17.1893 13.5762L5 13.5762C4.58579 13.5762 4.25 13.2404 4.25 12.8262C4.25 12.412 4.58579 12.0762 5 12.0762L17.1893 12.0762L12.4697 7.3565C12.1768 7.06361 12.1768 6.58873 12.4697 6.29584Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
              <button
                onClick={handleBuildLibrary}
                className="flex items-center justify-center px-2 gap-2 h-14 rounded-full bg-[#00C7A8]"
              >
                <span className="pl-2 text-lg font-medium text-white">
                  Build a Library
                </span>
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#009780]">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.4697 6.29584C12.7626 6.00295 13.2374 6.00295 13.5303 6.29584L19.5303 12.2958C19.8232 12.5887 19.8232 13.0636 19.5303 13.3565L13.5303 19.3565C13.2374 19.6494 12.7626 19.6494 12.4697 19.3565C12.1768 19.0636 12.1768 18.5887 12.4697 18.2958L17.1893 13.5762L5 13.5762C4.58579 13.5762 4.25 13.2404 4.25 12.8262C4.25 12.412 4.58579 12.0762 5 12.0762L17.1893 12.0762L12.4697 7.3565C12.1768 7.06361 12.1768 6.58873 12.4697 6.29584Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button> */}
            </div>
          )}

          {/* SECTION END */}
        </div>
      </div>
    );
  };

  const renderRightSection = () => {
    if (notCompleted('indication.edit')) return null;

    const sectionProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
    };

    return (
      <div className="flex flex-col w-2/5">
        <div className="overflow-y-auto max-h-[calc(100vh-60px)] mt-4 py-2 pl-6">
          {(isCurrentStep('inclusionCriteria.edit') ||
            isCurrentStep('inclusionCriteria.correct')) && (
            <InclusionCriteriaListSection {...sectionProps} />
          )}

          {(isCurrentStep('exclusionCriteria.edit') ||
            isCurrentStep('exclusionCriteria.correct')) && (
            <ExclusionCriteriaListSection {...sectionProps} />
          )}

          {(isCurrentStep('outcomes.edit') ||
            isCurrentStep('outcomes.correct')) &&
            featureFlags.outcomes && <OutcomesListSection {...sectionProps} />}

          {hasStep('brainstorm.done') && (
            <BrainstormDoneSection
              {...sectionProps}
              handleBuildLibrary={handleBuildLibrary}
            />
          )}
        </div>
      </div>
    );
  };

  if (brainstormLoading) {
    return (
      <ProjectLayout title="KadenceBio">
        <div className="flex items-center justify-center h-screen">
          <NeurouteAnimatedSearching />
        </div>
      </ProjectLayout>
    );
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="relative flex flex-col flex-grow min-h-screen p-0">
        <div className="flex flex-row w-full p-0">
          {renderLeftColumn()}
          {renderMainSection()}
          {renderRightSection()}
        </div>
      </main>
    </ProjectLayout>
  );
};

export default BrainstormCreateEditPage;
