import React from 'react';
import { Author } from '@/types/Author';
import { UserProfile } from '@/types/UserProfile';

const getInitials = (name: string): string => {
  const words = name.split(/[\s.,]+/).filter((word) => word.length > 0);
  if (words.length === 0) {
    return '';
  }
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  }
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

interface AuthorAvatarProps {
  author: Author | UserProfile | null;
  styleVariant?: 'default' | 'simple';
}

const AuthorAvatar: React.FC<AuthorAvatarProps> = ({
  author,
  styleVariant = 'default',
}) => {
  if (!author) return null;

  const initials = getInitials(author.displayName || author.id || 'U');

  const renderAvatar = (size: string) => {
    if (author.photoURL) {
      return (
        <img
          src={author.photoURL}
          alt="Avatar"
          className={`${size} rounded-full`}
        />
      );
    } else {
      return (
        <div
          className={`${size} rounded-full bg-[#FC78F3] flex items-center justify-center`}
        >
          <span className="text-base font-semibold text-white">{initials}</span>
        </div>
      );
    }
  };

  if (styleVariant === 'simple') {
    return renderAvatar('w-10 h-10');
  }

  return (
    <div className="rounded-full p-0.25 border border-white border-opacity-50 bg-none">
      {/*<div className="p-1 bg-gray-900 rounded-full">*/}
      {renderAvatar('w-10 h-10')}
      {/*</div>*/}
    </div>
  );
};

export default AuthorAvatar;
