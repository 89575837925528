import 'react-phone-number-input/style.css';

import { useState } from 'react';

import { getApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import LoginRightLayout from '../kadencebio/components/LoginRightLayout';

const isMVP = true;

const schema = z
  .object({
    email: z.string().email().optional(),
    phoneNumber: z.string().optional(),
    password: z.string().min(8).max(100),
    rememberMe: z.boolean().nullish(),
  })
  .refine(
    (data) => data.email || data.phoneNumber,
    'Either email or phone number is required',
  );

function SignInPhoneOrEmailPage() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const [selectedTab, setSelectedTab] = useState<'phone' | 'email'>('email');
  const navigate = useNavigate();
  const location = useLocation();

  const app = getApp();
  const auth = getAuth(app);

  const handleTabClick = (tab: 'phone' | 'email') => {
    setSelectedTab(tab);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const onSubmit = async (data: any) => {
    try {
      const persistence = data.rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);

      if (selectedTab === 'email') {
        await signInWithEmailAndPassword(auth, data.email, data.password);
      } else {
        // TODO: Handle phone number sign-in
      }

      // Get the 'from' query parameter
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get('from');

      // Redirect to the 'from' URL if it exists and starts with '/', otherwise go to '/graph'
      if (from && from.startsWith('/')) {
        navigate(from);
      } else {
        window.location.href = '/kadencebio';
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'No user with these credentials was found.',
          });
          break;

        case 'auth/invalid-email':
          setError('email', {
            type: 'manual',
            message: 'Invalid email address.',
          });
          break;

        case 'auth/wrong-password':
          setError('password', {
            type: 'manual',
            message: 'Incorrect password.',
          });
          break;

        case 'auth/network-request-failed':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        case 'auth/too-many-requests':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Too many requests. Try again later.',
          });
          break;

        default:
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error(error);
          break;
      }
    }
  };

  // Helper function to get the error message
  function getErrorMessage(error: any): string | undefined {
    if (error) {
      if (typeof error.message === 'string') {
        return error.message;
      }
      if (typeof error.message === 'object') {
        // If the message is nested, recursively get the first string message
        for (const key in error.message) {
          if (typeof error.message[key] === 'string') {
            return error.message[key];
          }
        }
      }
    }
    return undefined;
  }

  const handleForgotPassword = () => {
    navigate('/login/forgot-password');
  };

  return (
    <div className="w-screen h-screen bg-[#1F1F23] flex justify-center items-center">
      <div className="flex items-center justify-center w-3/5 h-full">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="flex flex-col items-start justify-start gap-y-3">
            <h1 className="text-white text-[45px] leading-[60px] font-medium">
              Sign in
            </h1>
            <p className="text-[#9491A7] leading-4">
              Enter your details to log in to your account
            </p>
          </div>

          <form
            className="flex flex-col w-full gap-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col items-center justify-center w-full gap-y-4">
              <div className="flex flex-col items-start justify-start w-full gap-y-1">
                <div className="w-full border border-[#676578] bg-[#2C2B32] rounded-full flex justify-start items-center py-2 px-4 gap-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <circle cx={12} cy={5} r={3} />
                    <path d="M4 15a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v1.5a5.5 5.5 0 0 1-5.5 5.5h-5A5.5 5.5 0 0 1 4 16.5z" />
                  </svg>
                  <div className="flex flex-col items-start justify-start">
                    <label className="text-xs">Email</label>
                    <input
                      type="email"
                      className="p-0 text-xs font-medium text-white border-none"
                      {...register('email')}
                    />
                  </div>
                </div>
                {errors.email && (
                  <p className="text-xs text-red-500">
                    {getErrorMessage(errors.email)}
                  </p>
                )}
              </div>

              <div className="flex flex-col items-start justify-start w-full gap-y-1">
                <div className="w-full border border-[#676578] bg-[#2C2B32] rounded-full flex justify-start items-center py-2 px-4 gap-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <path d="M3 14.5A5.5 5.5 0 0 1 8.5 9h7a5.5 5.5 0 0 1 5.5 5.5v2a5.5 5.5 0 0 1-5.5 5.5h-7A5.5 5.5 0 0 1 3 16.5z" />
                    <path
                      strokeLinecap="round"
                      d="M7.5 9V5.375A3.375 3.375 0 0 1 10.875 2v0h2.302A3.323 3.323 0 0 1 16.5 5.323V9"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      d="M12 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 15v2"
                    />
                  </svg>
                  <div className="flex flex-col items-start justify-start">
                    <label className="text-xs">Password</label>
                    <input
                      type="password"
                      className="p-0 text-xs font-medium text-white border-none"
                      {...register('password')}
                    />
                  </div>
                </div>

                {errors.password && (
                  <p className="text-xs text-red-500">
                    {getErrorMessage(errors.password)}
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between w-full">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="w-4 h-4 border-[1.5px] border-[#676578] rounded-md"
                    {...register('rememberMe')}
                  />
                  <span className="text-sm leading-5">Remember Me</span>
                </label>

                <p className="flex text-sm leading-5 gap-x-1">
                  Forgot password?
                  <button
                    onClick={handleForgotPassword}
                    type="button"
                    className="text-[#00C7A8]"
                  >
                    Reset
                  </button>
                </p>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-[#00C7A8] py-2 px-4 rounded-full w-full flex justify-center items-center gap-x-2 text-black hover:bg-[#00b89b] transition-colors"
            >
              Login
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.4697 5.96967C12.7626 5.67678 13.2374 5.67678 13.5303 5.96967L19.5303 11.9697C19.8232 12.2626 19.8232 12.7374 19.5303 13.0303L13.5303 19.0303C13.2374 19.3232 12.7626 19.3232 12.4697 19.0303C12.1768 18.7374 12.1768 18.2626 12.4697 17.9697L17.1893 13.25L5 13.25C4.58579 13.25 4.25 12.9142 4.25 12.5C4.25 12.0858 4.58579 11.75 5 11.75L17.1893 11.75L12.4697 7.03033C12.1768 6.73744 12.1768 6.26256 12.4697 5.96967Z"
                  fill="black"
                />
              </svg>
            </button>
          </form>

          {/* Turning Off Sign Up for now */}
          {/* <p className="flex text-sm leading-5 gap-x-1">
            Don't have an account?
            <button type="button" className="text-[#00C7A8]">
              Sign up
            </button>
          </p> */}
        </div>
      </div>

      <LoginRightLayout />
    </div>
  );
}

export default SignInPhoneOrEmailPage;
