import React from 'react';

import { StepSectionProps } from '../stepSections/StepSectionProps';

const InclusionCriteriaListSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleCriteriaClick = (criteria: string) => {
    if (brainstorm.inclusionCriteria?.includes(criteria)) {
      // Remove the criteria if it's already selected
      setBrainstorm({
        ...brainstorm,
        inclusionCriteria: brainstorm.inclusionCriteria.filter(
          (item) => item !== criteria,
        ),
      });
    } else {
      // Add the criteria if it's not selected
      setBrainstorm({
        ...brainstorm,
        inclusionCriteria: [...(brainstorm.inclusionCriteria || []), criteria],
      });
    }
  };

  return (
    <div className="mb-8">
      <h2 className="mb-4 text-2xl font-medium text-white">Population</h2>
      <p className="text-[#9491a7] mb-4">
        All I/C across relevant studies related to {brainstorm.interventionType}
      </p>
      <ul className="space-y-4">
        {brainstorm.availableInclusionCriteria.map((criteria, index) => (
          <li
            key={index}
            className={`cursor-pointer rounded-2xl px-6 py-4 border ${
              brainstorm.inclusionCriteria.includes(criteria)
                ? 'bg-[#00C7A8] bg-opacity-10 text-[#00C7A8] border-[#00C7A8]'
                : 'bg-[#D9D9D9] bg-opacity-10 text-white border-transparent'
            }`}
            onClick={() => handleCriteriaClick(criteria)}
          >
            {criteria}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InclusionCriteriaListSection;
