import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchOutcomes, OutcomeMeasure } from '@/api/brainstormApi';

const OutcomesCard: React.FC<{ outcome: OutcomeMeasure }> = ({ outcome }) => (
  <div className="text-[#bab9c4] text-[22px] font-light mb-4 p-4 rounded hover:bg-[#D9D9D9] hover:bg-opacity-10">
    <ul className="text-[#bab9c4] text-[22px] font-light space-y-2 list-disc pl-4">
      <li className="text-[#bab9c4] text-[22px] font-light">
        <span className="text-white">Measured Outcome: </span>
        <span className="text-[#bab9c4] text-[22px] font-light">
          {outcome.O}
        </span>
      </li>
      <li className="text-[#bab9c4] text-[22px] font-light">
        <span className="text-white">Description: </span>
        <span className="text-[#bab9c4] text-[22px] font-light">
          {outcome.D}
        </span>
      </li>
      <li className="text-[#bab9c4] text-[22px] font-light">
        <span className="text-white">Timeline: </span>
        <span className="text-[#bab9c4] text-[22px] font-light">
          {outcome.T}
        </span>
      </li>
    </ul>
  </div>
);

const OutcomesSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleOutcomeClick = (outcomeId: string) => {
    let outcomes = brainstorm.outcomes;
    if (!Array.isArray(outcomes)) {
      outcomes = [];
    }
    setBrainstorm({
      ...brainstorm,
      outcomes: outcomes.filter((item) => item !== outcomeId),
    });
  };

  return (
    <>
      {isSection('outcomes') && (
        <HeaderTitle
          prefix="I want my impact to be obvious"
          value=""
          className="mt-14"
        />
      )}

      {isCurrentStep('outcomes.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchOutcomes({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              intervention_type: brainstorm.interventionType,
            });
            console.log('fetch outcomes', data);
            if (data) {
              setBrainstorm({
                ...brainstorm,
                availableOutcomes: data,
              });
            }
          }}
          searchText="searching for relevant outcomes"
          onNextStep={() => nextStep('outcomes.correct')}
        />
      )}

      {(isCurrentStep('outcomes.edit') ||
        isCurrentStep('outcomes.correct')) && (
        <>
          <DescriptionBlock>
            I am selecting the following outcomes:
            <ul className="list-none pl-0 space-y-2">
              {Array.isArray(brainstorm.outcomes) &&
              brainstorm.outcomes.length > 0 ? (
                brainstorm.outcomes.map((outcomeId, index) => {
                  const outcome = brainstorm.availableOutcomes?.find(
                    (o) => o.id === outcomeId,
                  );
                  if (!outcome) return null;
                  return (
                    <li
                      key={index}
                      className="cursor-pointer hover:text-red-400 transition-colors"
                      onClick={() => handleOutcomeClick(outcomeId)}
                    >
                      <OutcomesCard outcome={outcome} />
                    </li>
                  );
                })
              ) : (
                <li className="text-gray-500 italic">
                  No outcomes selected yet. You can select outcomes from the
                  list on the right.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}

      {(isCurrentStep('outcomes.edit') ||
        isCurrentStep('outcomes.correct')) && (
        <>
          <CorrectMeBlock
            onClick={() => {
              setCurrentStep('outcomes.edit');
            }}
            text="Modify outcomes"
          />
          <ThatLooksRightToMe
            onNextStep={() => nextStep('timing.edit', 'outcomes')}
          />
        </>
      )}

      {hasStep('outcomes') && (
        <>
          <DescriptionBlock>
            You selected the following outcomes:
            <ul className="list-none pl-0 space-y-2">
              {Array.isArray(brainstorm.outcomes) &&
              brainstorm.outcomes.length > 0 ? (
                brainstorm.outcomes.map((outcomeId, index) => {
                  const outcome = brainstorm.availableOutcomes?.find(
                    (o) => o.id === outcomeId,
                  );
                  if (!outcome) return null;
                  return (
                    <li
                      key={index}
                      className="cursor-pointer transition-colors"
                    >
                      <OutcomesCard outcome={outcome} />
                    </li>
                  );
                })
              ) : (
                <li className="text-gray-500 italic">
                  No outcomes selected yet. You can select outcomes from the
                  list on the right.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}
    </>
  );
};

export default OutcomesSection;
