import React from 'react';

import { getApp } from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import LoginRightLayout from '../kadencebio/components/LoginRightLayout';

const schema = z.object({
  email: z.string().email().optional(),
});

function ResetPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitted },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const app = getApp();
    const auth = getAuth(app);

    try {
      await sendPasswordResetEmail(auth, data.email);
      console.log('Reset password link sent to:', data.email);
    } catch (error: any) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError('email', {
            type: 'manual',
            message: 'No user found with this email address.',
          });
          break;

        case 'auth/invalid-email':
          setError('email', {
            type: 'manual',
            message: 'Invalid email address.',
          });
          break;

        case 'auth/too-many-requests':
          setError('email', {
            type: 'manual',
            message: 'Too many requests. Try again later.',
          });
          break;

        case 'auth/network-request-failed':
          setError('email', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        default:
          setError('email', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error('Error: ', error);
          break;
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // Helper function to get the error message
  function getErrorMessage(error: any): string | undefined {
    if (error) {
      if (typeof error.message === 'string') {
        return error.message;
      }
      if (typeof error.message === 'object') {
        // If the message is nested, recursively get the first string message
        for (const key in error.message) {
          if (typeof error.message[key] === 'string') {
            return error.message[key];
          }
        }
      }
    }
    return undefined;
  }

  return (
    <div className="w-screen h-screen bg-[#1F1F23] flex justify-center items-center">
      <div className="flex items-center justify-center w-3/5 h-full">
        <button
          onClick={handleBackClick}
          className="absolute top-5 left-5 flex items-center gap-2 text-[#9491A7] hover:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
          Back
        </button>
        <div className="relative flex flex-col items-center justify-center gap-8">
          <div className="flex flex-col items-start justify-start gap-y-3">
            <h1 className="text-white text-[45px] leading-[60px] font-medium">
              Reset Password
            </h1>
            <p className="text-[#9491A7] leading-4">
              Enter your email to reset your password
            </p>
          </div>

          <form
            className="flex flex-col w-full gap-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col items-center justify-center w-full gap-y-4">
              <div className="flex flex-col items-start justify-start w-full gap-y-1">
                <div className="w-full border border-[#676578] bg-[#2C2B32] rounded-full flex justify-start items-center py-2 px-4 gap-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <circle cx={12} cy={5} r={3} />
                    <path d="M4 15a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v1.5a5.5 5.5 0 0 1-5.5 5.5h-5A5.5 5.5 0 0 1 4 16.5z" />
                  </svg>
                  <div className="flex flex-col items-start justify-start">
                    <label className="text-xs">Email</label>
                    <input
                      type="email"
                      className="p-0 text-xs font-medium text-white border-none"
                      {...register('email')}
                    />
                  </div>
                </div>
                {errors.email && (
                  <p className="text-xs text-red-500">
                    {getErrorMessage(errors.email)}
                  </p>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-[#00C7A8] py-2 px-4 rounded-full w-full flex justify-center items-center gap-x-2 text-black hover:bg-[#00b89b] transition-colors"
            >
              Send Reset Link
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.4697 5.96967C12.7626 5.67678 13.2374 5.67678 13.5303 5.96967L19.5303 11.9697C19.8232 12.2626 19.8232 12.7374 19.5303 13.0303L13.5303 19.0303C13.2374 19.3232 12.7626 19.3232 12.4697 19.0303C12.1768 18.7374 12.1768 18.2626 12.4697 17.9697L17.1893 13.25L5 13.25C4.58579 13.25 4.25 12.9142 4.25 12.5C4.25 12.0858 4.58579 11.75 5 11.75L17.1893 11.75L12.4697 7.03033C12.1768 6.73744 12.1768 6.26256 12.4697 5.96967Z"
                  fill="black"
                />
              </svg>
            </button>

            {isSubmitted && !errors.email && (
              <div className="text-sm text-primary">
                If an account with this email exists,<br></br> we'll send you a
                link to reset your password.
              </div>
            )}
          </form>
        </div>
      </div>

      <LoginRightLayout />
    </div>
  );
}

export default ResetPasswordPage;
