import { GraphDataInterface } from '../RoutesCreateEditPage';

type NoChartDataMessageViewProps = {
  graphData: GraphDataInterface;
};

export default function NoChartDataMessageView(
  props: NoChartDataMessageViewProps,
) {
  const { graphData } = props;
  const { dates, scenarioData } = graphData;
  let message = '';

  if (scenarioData.length === 0 && dates.start && dates.end) {
    console.log('scenarioData', scenarioData);
    console.log('dates', dates);
    message = 'No data available.\nPlease select a different date range.';
  } else if (scenarioData.length === 0) {
    message = 'No data available.';
  }

  if (message === '') {
    return null;
  }

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <p className="text-white text-lg">{message}</p>
    </div>
  );
}
