import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { auth } from '@/core/setup_firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  doc,
  setDoc,
  addDoc,
  collection,
  getDoc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '@/core/setup_firebase';

import RoutesGraph from './graph/RoutesGraph';
import TimelineChart from './graph/TimelineChart';
import { SiteMapSite, TimelineInfo } from '@/types/SiteMap';
import { ScenarioColors } from '@/features/enrollment_scenario/colors_data';
import { useLoadBrainstorm } from '@/hooks/useLoadBrainstorm';
import { useLoadLibrary } from '@/hooks/useLoadLibrary';
import { useLoadSitemap } from '@/hooks/useLoadSitemap';
import { Brainstorm, FirebaseDocumentId } from '@/types/KadenceBio';
import { useLoadSiteRand } from '@/hooks/useLoadSiteRand';
import NeurouteAnimatedSearching from './components/NeurouteAnimatedSearching';
import { uniqueId } from 'lodash';

/*
--------------------------------------------------------------------------------
Consolidated types
--------------------------------------------------------------------------------
*/

type SitesTab = 'all_sites' | 'saved_sites';
type ViewMode = 'model' | 'map' | 'table' | 'timeline';

type ScenarioColor = {
  mostLikely: string;
  mostLikelyIcon: string;

  bestCase: string;
  bestCaseIcon: string;

  worstCase: string;
  worstCaseIcon: string;
};

type ScenarioDataItem = {
  date: Date;
  value: number;
};

export interface GraphDataInterface {
  dates: {
    start: string | null;
    end: string | null;
  };

  scenarioName: string;
  scenarioData: ScenarioDataItem[];

  cohortSize: {
    min: number;
    target: number;
  };

  colors: ScenarioColor[];
  fullScreen: boolean;
  isChartAnimationEnabled: boolean;
  viewMode: ViewMode;
  sitesTab: SitesTab;
  isGridVisible: boolean;
}

/*
--------------------------------------------------------------------------------
Consolidated fake data
--------------------------------------------------------------------------------
*/

export type RouteData = {
  id: FirebaseDocumentId;
  userId: string;
  name: string;
  brainstormId: string;
  libraryId: string;
  sitemapId: string;
  sitemapSelectedSites: SiteMapSite[]; // all sites
  timing: string;
  actualPatients: number;
  targetPatients: number;
  allConvertedTimelineSites: TimelineInfo[]; // all sites from sitemapSelectedSites
  // timelineSites: TimelineInfo[]; // all sites from sitemapSelectedSites
  // selectedSites: string[];
  // graph
  graphData: GraphDataInterface;
};

const onlyDate = (date: Date | Timestamp | string) => {
  let dateObj: Date;
  if (date instanceof Date) {
    dateObj = date;
  } else if (date instanceof Timestamp) {
    dateObj = date.toDate();
  } else {
    dateObj = new Date(date);
  }

  if (isNaN(dateObj.getTime())) {
    // console.log('Invalid date provided', date, dateObj);
    throw new Error('Invalid date provided');
  }
  return new Date(dateObj.setHours(0, 0, 0, 0));
};

const onlyDateString = (date: Date) => {
  return onlyDate(date).toISOString();
};

const getDisplayName = (brainstorm: Brainstorm) => {
  const indication = brainstorm.indication || 'N/A';
  const intervention =
    brainstorm.interventionType || brainstorm.interventionFreeText || 'N/A';
  return `${indication} - ${intervention}`;
};

const convertSitemapSiteToTimelineInfo = (
  sitemapSite: SiteMapSite,
  timing: string,
): TimelineInfo => {
  return {
    taskId: uniqueId(),
    name: sitemapSite.site_name,
    rand: sitemapSite.rand || 0, // this should come from the /routes/get-site-rand
    firstPatientStartDate: onlyDate(new Date()),
    firstPatientEndDate: onlyDate(new Date(timing)),
    isSelected: true,
  };
};
const initRoutesData: RouteData = {
  id: '',
  userId: '',
  name: '',
  brainstormId: '',
  libraryId: '',
  sitemapId: '',
  sitemapSelectedSites: [],
  timing: '2025-07-01',
  actualPatients: 0, // calculated
  targetPatients: 0,
  //
  allConvertedTimelineSites: [],
  // timelineSites: [],
  // selectedSites: [],
  // Graph Data
  graphData: {
    dates: {
      start: onlyDateString(new Date()),
      end: onlyDateString(new Date()),
    },
    scenarioName: 'Scenario 1',
    scenarioData: [],

    cohortSize: {
      min: 0,
      target: 0,
    },

    colors: ScenarioColors,
    fullScreen: false,
    isChartAnimationEnabled: true,
    viewMode: 'timeline',
    sitesTab: 'all_sites',
    isGridVisible: true,
  },
};

// Rename type to reflect new purpose
type LoadedState = {
  route: boolean;
  sitemap: boolean;
  library: boolean;
  brainstorm: boolean;
  siteRand: boolean;
};

const calculateRouteData = (
  timelineSites: TimelineInfo[],
  currentGraphData: GraphDataInterface,
): Pick<RouteData, 'actualPatients' | 'graphData'> => {
  const selectedTimelineSites = timelineSites.filter((site) => site.isSelected);

  // no sites selected
  if (selectedTimelineSites.length === 0) {
    return {
      actualPatients: 0,
      graphData: {
        ...currentGraphData,
        scenarioData: [],
        dates: { start: null, end: null },
      },
    };
  }

  // Get date range
  const startDate = selectedTimelineSites.reduce((earliest, site) => {
    const siteStart = onlyDateString(site.firstPatientStartDate);
    return siteStart < earliest ? siteStart : earliest;
  }, onlyDateString(selectedTimelineSites[0].firstPatientStartDate));

  const endDate = selectedTimelineSites.reduce((latest, site) => {
    const siteEnd = onlyDateString(site.firstPatientEndDate);
    return siteEnd > latest ? siteEnd : latest;
  }, onlyDateString(selectedTimelineSites[0].firstPatientEndDate));

  // Initialize scenarioData with zeros
  const scenarioData: ScenarioDataItem[] = [];
  const perSiteData: { [key: string]: ScenarioDataItem[] } = {};

  // Initialize arrays
  for (
    let date = new Date(startDate);
    date <= new Date(endDate);
    date.setDate(date.getDate() + 1)
  ) {
    scenarioData.push({
      date: new Date(date),
      value: 0,
    });
  }

  // Calculate per-site data
  selectedTimelineSites.forEach((site) => {
    if (!site.taskId) return;
    perSiteData[site.taskId] = [];

    for (
      let date = new Date(startDate);
      date <= new Date(endDate);
      date.setDate(date.getDate() + 1)
    ) {
      const currentDate = onlyDate(new Date(date));
      const siteStartDate = onlyDate(site.firstPatientStartDate);
      const siteEndDate = onlyDate(site.firstPatientEndDate);

      let value = 0;
      if (currentDate >= siteStartDate && currentDate <= siteEndDate) {
        value = site.rand / 30;
      }

      perSiteData[site.taskId].push({
        date: new Date(date),
        value: value,
      });
    }
  });

  // Sum up all site data into scenarioData with cumulative values
  let runningTotal = 0;
  scenarioData.forEach((_, index) => {
    const dailySum = Object.values(perSiteData).reduce(
      (sum, siteData) => sum + siteData[index].value,
      0,
    );
    runningTotal += dailySum;
    scenarioData[index].value = runningTotal;
  });

  return {
    actualPatients: Math.floor(runningTotal),
    graphData: {
      ...currentGraphData,
      scenarioData: scenarioData,
      dates: { start: startDate, end: endDate },
    },
  };
};

const RoutesCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // loaders
  const { fetchBrainstorm } = useLoadBrainstorm();
  const { fetchLibrary } = useLoadLibrary();
  const { fetchSitemap } = useLoadSitemap();
  const { fetchSiteRand } = useLoadSiteRand();
  const [routeData, setRouteData] = useState<RouteData>(initRoutesData);

  // Rename state to track loaded status
  const [isLoadedState, setIsLoadedState] = useState<LoadedState>({
    route: false,
    sitemap: false,
    library: false,
    brainstorm: false,
    siteRand: false,
  });

  // Update helper function
  const updateIsLoadedState = (key: keyof LoadedState, value: boolean) => {
    setIsLoadedState((prev) => ({ ...prev, [key]: value }));
    console.log('isLoadedState', isLoadedState);
  };

  // Update loading check
  const isLoading = Object.values(isLoadedState).some((state) => !state);
  const isDependenciesLoaded = Object.values(isLoadedState).every(
    (state) => state,
  );

  // Update graph data
  const updateGraphData = (newGraphData: Partial<GraphDataInterface>) => {
    setRouteData((prev) => ({
      ...prev,
      graphData: {
        ...prev.graphData,
        ...newGraphData,
      },
    }));
  };
  /* 
  useEffect(() => {
    const newTimelineSites: TimelineInfo[] = [];
    // Use Set to ensure unique sites during conversion
    const selectedSites: string[] = [];

    routeData.sitemapSelectedSites.forEach((site) => {
      if (!selectedSites.includes(site.site_name)) {
        selectedSites.push(site.site_name);
        newTimelineSites.push(
          convertSitemapSiteToTimelineInfo(site, routeData.timing),
        );
      }
    });

    setRouteData((prev) => ({
      ...prev,
      allConvertedTimelineSites: newTimelineSites,
      timelineSites: newTimelineSites,
      // Ensure selectedSites are unique
      selectedSites: selectedSites,
    }));
  }, [routeData.sitemapSelectedSites, routeData.timing]); */

  // Auth check effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // Update the route loading effect
  useEffect(() => {
    const loadRoute = async () => {
      if (!auth.currentUser) return;

      try {
        // Create mode
        if (!id) {
          const sitemapId = searchParams.get('sitemapId');
          if (sitemapId) {
            await createRouteData();
          }
          updateIsLoadedState('route', true); // Add this for create mode
          return;
        }

        // Edit mode
        await loadRouteData(id);
        // Note: loadRouteData already sets the loaded states
      } catch (error) {
        console.error('Error loading route:', error);
      }
    };

    if (auth.currentUser) {
      loadRoute();
    }
  }, [id, auth.currentUser, searchParams]);

  const handleTimelineChartChange = useCallback(
    (allConvertedTimelineSites: TimelineInfo[]) => {
      setRouteData((prev) => ({
        ...prev,
        allConvertedTimelineSites,
      }));
    },
    [], // no dependencies needed
  );

  // Update the useEffect to use the new function
  useEffect(() => {
    if (!routeData.allConvertedTimelineSites) return;
    const newData = calculateRouteData(
      routeData.allConvertedTimelineSites,
      routeData.graphData,
    );
    console.log('calc graphData', routeData.graphData);

    setRouteData((prev) => ({
      ...prev,
      ...newData,
    }));
  }, [routeData.allConvertedTimelineSites]);

  // Update the route update method
  const updateRouteData = useCallback(async () => {
    if (!auth.currentUser?.uid || !id) {
      console.warn('Cannot update: missing requirements', {
        userId: auth.currentUser?.uid,
        id,
      });
      return;
    }

    try {
      // Create a storage version without calculated data
      const storageData = {
        ...routeData,
        userId: auth.currentUser.uid,
        updatedAt: Timestamp.now(),
        graphData: {
          ...routeData.graphData,
          scenarioData: [], // Empty for storage only
        },
      };

      await setDoc(doc(db, 'routes', id), storageData);
      console.log('Route updated successfully');
    } catch (error) {
      console.error('Error updating route:', error);
    }
  }, [routeData, id]);

  // Load the route data from firestore
  // and dependencies
  const loadRouteData = useCallback(async (id: string) => {
    if (!id) return null;

    try {
      const routeRef = doc(db, 'routes', id);
      const routeSnap = await getDoc(routeRef);

      if (routeSnap.exists()) {
        const newRouteData = routeSnap.data() as RouteData;
        updateIsLoadedState('route', true);

        const sitemapData = await fetchSitemap(newRouteData.sitemapId);
        if (sitemapData) {
          updateIsLoadedState('sitemap', true);

          const brainstormData = await fetchBrainstorm(
            sitemapData.brainstormId,
          );
          if (brainstormData) {
            updateIsLoadedState('brainstorm', true);
            newRouteData.brainstormId = brainstormData.id;
            newRouteData.name = getDisplayName(brainstormData);
            newRouteData.targetPatients = brainstormData.patientCount;
            newRouteData.timing = brainstormData.timing;
            newRouteData.graphData.scenarioName =
              getDisplayName(brainstormData);
            newRouteData.graphData.cohortSize.target =
              brainstormData.patientCount;
            newRouteData.graphData.scenarioData = [];
          } else {
            console.error('Failed to load brainstorm');
            return null;
          }

          const libraryData = await fetchLibrary(sitemapData.libraryId);
          if (libraryData) {
            updateIsLoadedState('library', true);
            newRouteData.libraryId = libraryData.id;
          } else {
            console.error('Failed to load library');
            return null;
          }

          // console.log('Loading sitemapSelectedSites...');

          let sitemapSelectedSites: SiteMapSite[] = [];
          if (routeSnap.data().sitemapSelectedSites) {
            sitemapSelectedSites = routeSnap.data().sitemapSelectedSites;
          } else {
            // get the site_rand for each site in sitemapSelectedSites
            await Promise.all(
              sitemapSelectedSites.map(async (site) => {
                const siteRand = await fetchSiteRand({
                  site_name: site.site_name,
                  condition: brainstormData.indication,
                  intervention_type: brainstormData.interventionType,
                  relevance: brainstormData.relevance,
                  phase: brainstormData.phase,
                });
                // console.log('siteRand', siteRand);
                site.rand = siteRand;
              }),
            );
          }
          newRouteData.sitemapSelectedSites = sitemapSelectedSites;
          updateIsLoadedState('siteRand', true);

          // convert sitemapSelectedSites to timelineSites
          //  but only if they don't exist yet
          if (!newRouteData.allConvertedTimelineSites) {
            const allConvertedTimelineSites = sitemapSelectedSites.map((site) =>
              convertSitemapSiteToTimelineInfo(site, newRouteData.timing),
            );
            newRouteData.allConvertedTimelineSites = allConvertedTimelineSites;
          }

          // console.log('newRouteData loaded and sorted', newRouteData);
          setRouteData(newRouteData);
          return newRouteData;
        }
      }
      return null;
    } catch (error) {
      console.error('Error in loadRouteData:', error);
      return null;
    }
  }, []);

  // New create method that handles the full creation flow
  const createRouteData = useCallback(async () => {
    if (!auth.currentUser?.uid) {
      console.warn('Cannot create: user not authenticated');
      return;
    }

    const sitemapId = searchParams.get('sitemapId');
    if (!sitemapId) {
      console.warn('Cannot create: missing sitemapId');
      return;
    }

    try {
      // Load dependencies sequentially
      console.log('Loading sitemap...');
      const sitemapData = await fetchSitemap(sitemapId);
      if (!sitemapData) {
        throw new Error('Failed to load sitemap');
      }

      console.log('Loading library...');
      const libraryData = await fetchLibrary(sitemapData.libraryId);
      if (!libraryData) {
        throw new Error('Failed to load library');
      }

      console.log('Loading brainstorm...');
      const brainstormData = await fetchBrainstorm(sitemapData.brainstormId);
      if (!brainstormData) {
        throw new Error('Failed to load brainstorm');
      }

      // console.log('Converting sitemapSelectedSites to timelineSites...');
      const allConvertedTimelineSites = sitemapData.selectedSites.map((site) =>
        convertSitemapSiteToTimelineInfo(site, initRoutesData.timing),
      );
      // console.log('allConvertedTimelineSites', allConvertedTimelineSites);
      // get the site_rand for each site in sitemapSelectedSites
      await Promise.all(
        allConvertedTimelineSites.map(async (site) => {
          const siteRand = await fetchSiteRand({
            site_name: site.name,
            condition: brainstormData.indication,
            intervention_type: brainstormData.interventionType,
            relevance: brainstormData.relevance,
            phase: brainstormData.phase,
          });
          // // console.log('siteRand', siteRand);
          site.rand = siteRand;
        }),
      );

      // Calculate initial route data
      // console.log('Calculating initial route data...');
      const calculatedData = calculateRouteData(
        allConvertedTimelineSites,
        initRoutesData.graphData,
      );

      // Prepare route data
      const newRouteData = {
        ...initRoutesData,
        userId: auth.currentUser.uid,
        sitemapId: sitemapId,
        brainstormId: sitemapData.brainstormId,
        libraryId: sitemapData.libraryId,
        sitemapSelectedSites: sitemapData.sites || [],
        allConvertedTimelineSites,
        ...calculatedData,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      // console.log('newRouteData', newRouteData);
      setRouteData(newRouteData);

      // Create the new route
      // console.log('Creating new route...');
      const newRouteRef = await addDoc(collection(db, 'routes'), newRouteData);
      // console.log('Route created successfully');

      // Navigate to edit page
      return navigate(`/kadencebio/route/edit/${newRouteRef.id}`);
    } catch (error) {
      console.error('Error in route creation flow:', error);
    }
  }, []);

  // Update the autosave effect to only handle updates
  useEffect(() => {
    if (!id || !auth.currentUser?.uid) return;

    const timeoutId = setTimeout(() => {
      console.log('Triggering autosave...');
      updateRouteData();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [routeData, id]);

  // Render left column
  const renderLeftColumn = () => {
    const formattedDate = routeData.timing
      ? new Date(routeData.timing).toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })
      : 'December 2025';

    const toggleSite = (site_index: number) => {
      // toggle isSelected for the site at site_index, keep the rest as is
      const currentIsSelected =
        routeData.allConvertedTimelineSites[site_index].isSelected;

      setRouteData((prev) => ({
        ...prev,
        allConvertedTimelineSites: prev.allConvertedTimelineSites.map(
          (site, index) => ({
            ...site,
            isSelected:
              index === site_index ? !currentIsSelected : site.isSelected,
          }),
        ),
      }));
    };

    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        <div className="p-6 pl-0 rounded-lg">
          <h1 className="text-4xl mb-4">
            <span className="text-[#9491A7]">
              This is how I will finish by{' '}
            </span>
            <span className="text-white">{formattedDate}</span>
          </h1>
        </div>

        <div className="flex mb-12 space-x-[10px]">
          <div>
            <div className="text-[#A9ABB2] text-sm mb-2">Actual Patients</div>
            <div className="text-4xl font-bold text-white">
              {routeData.actualPatients}
            </div>
          </div>
          <div>
            <div className="text-[#A9ABB2] text-sm mb-2">Target Patients</div>
            <div className="text-4xl font-bold text-white">
              {routeData.targetPatients}
            </div>
          </div>
        </div>
        <div className="p-6 pl-0 rounded-lg">
          <h2 className="text-[#9491a7] text-2xl mb-4">Selected Sites</h2>
          <div className="space-y-2 mt-3">
            {routeData.allConvertedTimelineSites.map((site, index) => {
              const isSelected = site.isSelected;
              return (
                <div
                  key={`${site.name}-${index}`}
                  className={`pl-2 cursor-pointer transition-colors ${
                    isSelected
                      ? 'text-white hover:text-[#E5E5EA]'
                      : 'text-[#9491a7] hover:text-[#6D6B7E]'
                  }`}
                  onClick={() => toggleSite(index)}
                >
                  {site.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  // Render main section
  const renderMainSection = () => {
    const loadingCardStyle =
      'bg-[#2A2A2E] p-6 rounded-lg w-full min-h-[300px] flex items-center justify-center text-white text-lg';

    return (
      <div className="overflow-y-auto mt-4 py-2 pr-0">
        {!isDependenciesLoaded ? (
          <div className={loadingCardStyle}>Loading data...</div>
        ) : (
          <>
            <RoutesGraph
              graphData={routeData.graphData}
              onChange={updateGraphData}
            />

            {routeData.allConvertedTimelineSites.filter(
              (site) => site.isSelected,
            ).length > 0 && (
              <TimelineChart
                key="timeline-chart"
                timelineSites={routeData.allConvertedTimelineSites}
                onChange={handleTimelineChartChange}
              />
            )}
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <ProjectLayout title="KadenceBio">
        <div className="flex items-center justify-center h-screen">
          <NeurouteAnimatedSearching />
          {/* <pre>{JSON.stringify(isLoadedState, null, 2)}</pre> */}
        </div>
      </ProjectLayout>
    );
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="flex-grow flex flex-col p-0 min-h-screen relative">
        <div className="flex p-0 flex-row w-full">
          {renderLeftColumn()}

          <div className="flex flex-col w-3/4">{renderMainSection()}</div>
        </div>
      </main>
    </ProjectLayout>
  );
};

export default RoutesCreateEditPage;
