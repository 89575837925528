import { db } from '@/core/setup_firebase';
import { useAuth } from '@/pages/auth/AuthContext';
import { Library } from '@/types/Library';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { BrainstormItem } from './BrainstormContainer';
import { Link } from 'react-router-dom';
import { SiteMap } from '@/types/SiteMap';
import { RouteData } from '../RoutesCreateEditPage';

type BrainstormNavItemProps = {
  brainstorm: BrainstormItem;
};

export const getLibraries = async (userId: string) => {
  try {
    const librariesRef = collection(db, 'libraries');
    const q = query(librariesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const libraryList: Library[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      //return the library list
      return { ...data, id: doc.id } as Library;
    });
    return libraryList;
  } catch (error) {
    console.error('Error fetching libraries: ', error);
    return [];
  }
};

export const getSiteMaps = async (userId: string) => {
  try {
    const siteMapsRef = collection(db, 'sitemaps');
    const q = query(siteMapsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const siteMapList: SiteMap[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id } as SiteMap;
    });
    return siteMapList;
  } catch (error) {
    console.error('Error fetching site maps: ', error);
    return [];
  }
};

const getRoutes = async (userId: string) => {
  try {
    const routesRef = collection(db, 'routes');
    const q = query(routesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const routeList: RouteData[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id } as RouteData & { id: string };
    });
    return routeList;
  } catch (error) {
    console.error('Error fetching routes: ', error);
    return [];
  }
};

const BrainstormNavItem = ({ brainstorm }: BrainstormNavItemProps) => {
  const [libraries, setLibraries] = useState<Library[]>([]);
  const [loadingLibraries, setLoadingLibraries] = useState<boolean>(false);
  const [hasLibrary, setHasLibrary] = useState<boolean>(false);
  const [siteMaps, setSiteMaps] = useState<SiteMap[]>([]);
  const [routes, setRoutes] = useState<RouteData[]>([]);

  const { currentUser } = useAuth();

  const [isLibrariesOpen, setIsLibrariesOpen] = React.useState(false);

  const handleLibrariesOpen = () => {
    setIsLibrariesOpen(!isLibrariesOpen);
  };

  useEffect(() => {
    const fetchLibraries = async () => {
      if (currentUser?.id) {
        setLoadingLibraries(true);
        const LibraryList = await getLibraries(currentUser.id);
        //filter the libraries according to the brainstorm id
        const filteredLibraries = LibraryList.filter(
          (library) => library.brainstormId === brainstorm.id,
        );
        setHasLibrary(filteredLibraries.length > 0);
        setLibraries(filteredLibraries);
        setLoadingLibraries(false);
      }
    };

    const fetchSiteMaps = async () => {
      if (currentUser?.id) {
        const siteMapsList = await getSiteMaps(currentUser.id);
        setSiteMaps(siteMapsList);
      }
    };

    const fetchRoutes = async () => {
      if (currentUser?.id) {
        const routeList = await getRoutes(currentUser.id);
        setRoutes(routeList);
      }
    };

    fetchLibraries();
    fetchSiteMaps();
    fetchRoutes();
  }, [currentUser?.id, brainstorm.id]);

  return (
    <>
      <div className="flex justify-between">
        <Link to={brainstorm.link} className="relative flex pl-2 gap-x-1">
          <div className="w-2 h-2 absolute top-1.5 rounded-full bg-[#7a788a]" />
          <ul className="pl-6">
            <li className="text-sm font-satoshi font-normal text-[#9492b2] whitespace-nowrap truncate line-clamp-1">
              {brainstorm.title}
            </li>
          </ul>
        </Link>
      </div>
      {hasLibrary && (
        <div className="flex flex-col pt-1.5">
          <div
            className="flex pl-6 cursor-pointer text-[#9492b2] hover:text-white transition-colors duration-200"
            onClick={handleLibrariesOpen}
          >
            <button
              className={`${
                isLibrariesOpen ? 'rotate-0' : 'rotate-180'
              } transition-transform duration-150`}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0068 9.84961L7.99963 6.15039L3.99186 9.84961"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>

            {/* Libraries list */}
            <span className="pl-2 text-sm font-medium font-satoshi">
              Libraries
            </span>
          </div>
          <div
            className={`pt-1.5 ${isLibrariesOpen ? 'flex flex-col' : 'hidden'}`}
          >
            {loadingLibraries ? (
              <p>Loading libraries</p>
            ) : libraries.length === 0 ? (
              <p>No libraries</p>
            ) : (
              //mapping the libraries
              <ul className="flex flex-col gap-1 pl-7">
                {libraries.map((library) => (
                  <li
                    key={library.id}
                    className="text-sm relative font-satoshi font-normal text-[#9492b2]"
                  >
                    <>
                      <div className="w-2 h-2 absolute top-1.5 rounded-full bg-[#403f4d]" />
                      <a
                        href={`/kadencebio/library/edit/${library.id}`}
                        className="pl-4 text-white truncate line-clamp-1 hover:underline"
                      >
                        {library.sponsors.length + 1} Sponsors
                      </a>
                    </>

                    {siteMaps.filter(
                      (siteMap) => siteMap.libraryId === library.id,
                    ).length > 0 && (
                      <>
                        <div className="pl-4 flex items-center pt-1.5">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_51_7259)">
                              <path
                                d="M5.99967 12.0007L1.33301 14.6673V4.00065L5.99967 1.33398M5.99967 12.0007L10.6663 14.6673M5.99967 12.0007V1.33398M10.6663 14.6673L14.6663 12.0007V1.33398L10.6663 4.00065M10.6663 14.6673V4.00065M10.6663 4.00065L5.99967 1.33398"
                                stroke="#9492B2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_51_7259">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="pl-3 text-sm font-medium text-white truncate font-satoshi line-clamp-1">
                            Site Maps
                          </span>
                        </div>

                        <ul className="flex flex-col gap-1 pl-6">
                          {siteMaps
                            .filter(
                              (siteMap) => siteMap.libraryId === library.id,
                            )
                            .map((siteMap) => (
                              <li
                                key={siteMap.id}
                                className="text-sm relative font-satoshi font-normal text-[#9492b2]"
                              >
                                <>
                                  <div className="w-2 h-2 absolute top-1.5 rounded-full bg-[#403f4d]" />
                                  <a
                                    href={`/kadencebio/sitemap/edit/${siteMap.id}`}
                                    className="pl-4 text-white truncate line-clamp-1 hover:underline"
                                  >
                                    {siteMap.sites.length > 0
                                      ? `${siteMap.sites.length} Sites`
                                      : 'No Sites'}
                                  </a>

                                  {routes.filter(
                                    (route) => route.sitemapId === siteMap.id,
                                  ).length > 0 && (
                                    <>
                                      <div className="pl-4 flex items-center pt-1.5">
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clipPath="url(#clip0_51_7259)">
                                            <path
                                              d="M5.99967 12.0007L1.33301 14.6673V4.00065L5.99967 1.33398M5.99967 12.0007L10.6663 14.6673M5.99967 12.0007V1.33398M10.6663 14.6673L14.6663 12.0007V1.33398L10.6663 4.00065M10.6663 14.6673V4.00065M10.6663 4.00065L5.99967 1.33398"
                                              stroke="#9492B2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_51_7259">
                                              <rect
                                                width="16"
                                                height="16"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                        <span className="pl-3 text-sm font-medium text-white truncate font-satoshi line-clamp-1">
                                          Routes
                                        </span>
                                      </div>

                                      <ul className="flex flex-col gap-1 pl-6">
                                        {routes
                                          .filter(
                                            (route) =>
                                              route.sitemapId === siteMap.id,
                                          )
                                          .map((route) => (
                                            <li
                                              key={route.id}
                                              className="text-sm relative font-satoshi font-normal text-[#9492b2]"
                                            >
                                              <>
                                                <div className="w-2 h-2 absolute top-1.5 rounded-full bg-[#403f4d]" />
                                                <a
                                                  href={`/kadencebio/route/edit/${route.id}`}
                                                  className="pl-4 text-white truncate line-clamp-1 hover:underline"
                                                >
                                                  Finish,{' '}
                                                  {new Date(
                                                    route.timing,
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      month: 'short',
                                                      year: 'numeric',
                                                    },
                                                  )}
                                                </a>
                                              </>
                                            </li>
                                          ))}
                                      </ul>
                                    </>
                                  )}
                                </>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default BrainstormNavItem;
