import React from 'react';
import { StepSectionProps } from '../stepSections/StepSectionProps';

const BrainstormDoneSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
  handleBuildLibrary,
}) => {
  return (
    <>
      <h2 className="text-white text-[36px] font-normal leading-[60.75px] text-left mb-6">
        I'm happy with this,
        <br />
        show me what's next
      </h2>
      <button
        onClick={handleBuildLibrary}
        className="flex items-center justify-center px-2 gap-2 h-14 rounded-full bg-[#00C7A8]"
      >
        <span className="pl-2 text-lg font-medium text-white">
          Build a Library
        </span>
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#009780]">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.4697 6.29584C12.7626 6.00295 13.2374 6.00295 13.5303 6.29584L19.5303 12.2958C19.8232 12.5887 19.8232 13.0636 19.5303 13.3565L13.5303 19.3565C13.2374 19.6494 12.7626 19.6494 12.4697 19.3565C12.1768 19.0636 12.1768 18.5887 12.4697 18.2958L17.1893 13.5762L5 13.5762C4.58579 13.5762 4.25 13.2404 4.25 12.8262C4.25 12.412 4.58579 12.0762 5 12.0762L17.1893 12.0762L12.4697 7.3565C12.1768 7.06361 12.1768 6.58873 12.4697 6.29584Z"
              fill="white"
            />
          </svg>
        </div>
      </button>
    </>
  );
};

export default BrainstormDoneSection;
