import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import InputStep from '../sections/InputStep';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchExclusionCriteria } from '@/api/brainstormApi';

const ExclusionCriteriaSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
  featureFlags,
}) => {
  const handleCriteriaClick = (criteria: string) => {
    setBrainstorm({
      ...brainstorm,
      exclusionCriteria: brainstorm.exclusionCriteria.filter(
        (item) => item !== criteria,
      ),
    });
  };

  return (
    <>
      {isSection('exclusionCriteria') && (
        <HeaderTitle prefix="I am not helping" value="" className="mt-14" />
      )}

      {isCurrentStep('exclusionCriteria.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchExclusionCriteria({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              intervention_type: brainstorm.interventionType,
            });
            console.log(data);
            if (data) {
              setBrainstorm({
                ...brainstorm,
                availableExclusionCriteria: data,
              });
            }
          }}
          searchText="searching for relevant exclusion criteria"
          onNextStep={() => nextStep('exclusionCriteria.correct')}
        />
      )}

      {(isCurrentStep('exclusionCriteria.edit') ||
        isCurrentStep('exclusionCriteria.correct')) && (
        <>
          <DescriptionBlock>
            I am selecting the following exclusion criteria:
            <ul className="pl-6 space-y-2 list-disc">
              {brainstorm.exclusionCriteria &&
              brainstorm.exclusionCriteria.length > 0 ? (
                brainstorm.exclusionCriteria.map((criteria, index) => (
                  <li
                    key={index}
                    className="transition-colors cursor-pointer hover:text-red-400"
                    onClick={() => handleCriteriaClick(criteria)}
                  >
                    {criteria}
                  </li>
                ))
              ) : (
                <li className="italic text-gray-500">
                  No exclusion criteria selected yet. You can select criteria
                  from the list on the right.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}

      {(isCurrentStep('exclusionCriteria.edit') ||
        isCurrentStep('exclusionCriteria.correct')) && (
        <>
          <CorrectMeBlock
            onClick={() => {
              setCurrentStep('exclusionCriteria.edit');
            }}
            text="Modify exclusion criteria"
          />
          <ThatLooksRightToMe
            onNextStep={() =>
              featureFlags?.outcomes
                ? nextStep('outcomes.search', 'exclusionCriteria')
                : nextStep('timing.edit', 'exclusionCriteria')
            }
          />
        </>
      )}

      {hasStep('exclusionCriteria') && (
        <>
          <DescriptionBlock>
            You selected the following exclusion criteria
            <ul className="pl-6 space-y-2 list-disc">
              {brainstorm.exclusionCriteria &&
              brainstorm.exclusionCriteria.length > 0 ? (
                brainstorm.exclusionCriteria.map((criteria, index) => (
                  <li key={index}>{criteria}</li>
                ))
              ) : (
                <li className="italic text-gray-500">
                  No exclusion criteria selected.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}
    </>
  );
};

export default ExclusionCriteriaSection;
